/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import CreatePaymentMethodRequest from '../model/CreatePaymentMethodRequest';
import Invoice from '../model/Invoice';
import PaymentMethod from '../model/PaymentMethod';
import Subscription from '../model/Subscription';
import SubscriptionChangeRequest from '../model/SubscriptionChangeRequest';
import SubscriptionState from '../model/SubscriptionState';

/**
* Billing service.
* @module api/BillingApi
* @version V1
*/
export default class BillingApi {

    /**
    * Constructs a new BillingApi. 
    * @alias module:api/BillingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the cancelSubscription operation.
     * @callback module:api/BillingApi~cancelSubscriptionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Subscription} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BillingApi~cancelSubscriptionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Subscription}
     */
    cancelSubscription(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Subscription;
      return this.apiClient.callApi(
        '/account/subscription', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllSubscriptionHistory operation.
     * @callback module:api/BillingApi~getAllSubscriptionHistoryCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Subscription>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BillingApi~getAllSubscriptionHistoryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Subscription>}
     */
    getAllSubscriptionHistory(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Subscription];
      return this.apiClient.callApi(
        '/account/subscription/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCurrentSubscription operation.
     * @callback module:api/BillingApi~getCurrentSubscriptionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Subscription} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BillingApi~getCurrentSubscriptionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Subscription}
     */
    getCurrentSubscription(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Subscription;
      return this.apiClient.callApi(
        '/account/subscription', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCurrentSubscriptionState operation.
     * @callback module:api/BillingApi~getCurrentSubscriptionStateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SubscriptionState} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BillingApi~getCurrentSubscriptionStateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SubscriptionState}
     */
    getCurrentSubscriptionState(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SubscriptionState;
      return this.apiClient.callApi(
        '/account/subscription-state', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getInvoices operation.
     * @callback module:api/BillingApi~getInvoicesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Invoice>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BillingApi~getInvoicesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Invoice>}
     */
    getInvoices(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Invoice];
      return this.apiClient.callApi(
        '/account/subscription/invoices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProrateIfUpgradeOrDowngrade operation.
     * @callback module:api/BillingApi~getProrateIfUpgradeOrDowngradeCallback
     * @param {String} error Error message, if any.
     * @param {Number} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} sku 
     * @param {module:api/BillingApi~getProrateIfUpgradeOrDowngradeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Number}
     */
    getProrateIfUpgradeOrDowngrade(sku, callback) {
      let postBody = null;
      // verify the required parameter 'sku' is set
      if (sku === undefined || sku === null) {
        throw new Error("Missing the required parameter 'sku' when calling getProrateIfUpgradeOrDowngrade");
      }

      let pathParams = {
        'sku': sku
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Number';
      return this.apiClient.callApi(
        '/account/subscription/{sku}/prorate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the hasActivePaymentMethod operation.
     * @callback module:api/BillingApi~hasActivePaymentMethodCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BillingApi~hasActivePaymentMethodCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    hasActivePaymentMethod(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/account/payment-method/active', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setCurrentCard operation.
     * @callback module:api/BillingApi~setCurrentCardCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CreatePaymentMethodRequest} opts.createPaymentMethodRequest 
     * @param {module:api/BillingApi~setCurrentCardCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setCurrentCard(opts, callback) {
      opts = opts || {};
      let postBody = opts['createPaymentMethodRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/account/card', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setCurrentCardViaToken operation.
     * @callback module:api/BillingApi~setCurrentCardViaTokenCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PaymentMethod} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} cardId 
     * @param {module:api/BillingApi~setCurrentCardViaTokenCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PaymentMethod}
     */
    setCurrentCardViaToken(cardId, callback) {
      let postBody = null;
      // verify the required parameter 'cardId' is set
      if (cardId === undefined || cardId === null) {
        throw new Error("Missing the required parameter 'cardId' when calling setCurrentCardViaToken");
      }

      let pathParams = {
        'cardId': cardId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PaymentMethod;
      return this.apiClient.callApi(
        '/account/card/{cardId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateSubscription operation.
     * @callback module:api/BillingApi~updateSubscriptionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Subscription} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SubscriptionChangeRequest} opts.subscriptionChangeRequest 
     * @param {module:api/BillingApi~updateSubscriptionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Subscription}
     */
    updateSubscription(opts, callback) {
      opts = opts || {};
      let postBody = opts['subscriptionChangeRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Subscription;
      return this.apiClient.callApi(
        '/account/subscription', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
