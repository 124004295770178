/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofCustomField from './ProofCustomField';

/**
 * The ProofListItem model module.
 * @module model/ProofListItem
 * @version V1
 */
class ProofListItem {
    /**
     * Constructs a new <code>ProofListItem</code>.
     * @alias module:model/ProofListItem
     */
    constructor() { 
        
        ProofListItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofListItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofListItem} obj Optional instance to populate.
     * @return {module:model/ProofListItem} The populated <code>ProofListItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofListItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('currentSender')) {
                obj['currentSender'] = ApiClient.convertToType(data['currentSender'], 'String');
            }
            if (data.hasOwnProperty('currentWorkflowName')) {
                obj['currentWorkflowName'] = ApiClient.convertToType(data['currentWorkflowName'], 'String');
            }
            if (data.hasOwnProperty('currentVersion')) {
                obj['currentVersion'] = ApiClient.convertToType(data['currentVersion'], 'Number');
            }
            if (data.hasOwnProperty('currentStageName')) {
                obj['currentStageName'] = ApiClient.convertToType(data['currentStageName'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'Number');
            }
            if (data.hasOwnProperty('docTypesUsed')) {
                obj['docTypesUsed'] = ApiClient.convertToType(data['docTypesUsed'], ['String']);
            }
            if (data.hasOwnProperty('dueDate')) {
                obj['dueDate'] = ApiClient.convertToType(data['dueDate'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('archived')) {
                obj['archived'] = ApiClient.convertToType(data['archived'], 'Boolean');
            }
            if (data.hasOwnProperty('customFields')) {
                obj['customFields'] = ApiClient.convertToType(data['customFields'], [ProofCustomField]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ProofListItem.prototype['id'] = undefined;

/**
 * @member {String} currentSender
 */
ProofListItem.prototype['currentSender'] = undefined;

/**
 * @member {String} currentWorkflowName
 */
ProofListItem.prototype['currentWorkflowName'] = undefined;

/**
 * @member {Number} currentVersion
 */
ProofListItem.prototype['currentVersion'] = undefined;

/**
 * @member {String} currentStageName
 */
ProofListItem.prototype['currentStageName'] = undefined;

/**
 * @member {String} name
 */
ProofListItem.prototype['name'] = undefined;

/**
 * @member {Number} status
 */
ProofListItem.prototype['status'] = undefined;

/**
 * @member {Array.<String>} docTypesUsed
 */
ProofListItem.prototype['docTypesUsed'] = undefined;

/**
 * @member {Date} dueDate
 */
ProofListItem.prototype['dueDate'] = undefined;

/**
 * @member {Date} createdAt
 */
ProofListItem.prototype['createdAt'] = undefined;

/**
 * @member {Boolean} archived
 */
ProofListItem.prototype['archived'] = undefined;

/**
 * @member {Array.<module:model/ProofCustomField>} customFields
 */
ProofListItem.prototype['customFields'] = undefined;






export default ProofListItem;

