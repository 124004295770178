/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AccountBannerItem from './AccountBannerItem';
import AccountProofProperty from './AccountProofProperty';
import AccountThemeSettings from './AccountThemeSettings';
import AshoreUserRole from './AshoreUserRole';
import AshoreUsersTeam from './AshoreUsersTeam';
import EmailSendData from './EmailSendData';
import Subscription from './Subscription';
import SubscriptionState from './SubscriptionState';

/**
 * The Account model module.
 * @module model/Account
 * @version V1
 */
class Account {
    /**
     * Constructs a new <code>Account</code>.
     * @alias module:model/Account
     */
    constructor() { 
        
        Account.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Account</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Account} obj Optional instance to populate.
     * @return {module:model/Account} The populated <code>Account</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Account();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('resellerId')) {
                obj['resellerId'] = ApiClient.convertToType(data['resellerId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('whiteLabelDomainName')) {
                obj['whiteLabelDomainName'] = ApiClient.convertToType(data['whiteLabelDomainName'], 'String');
            }
            if (data.hasOwnProperty('whiteLabelLogoUri')) {
                obj['whiteLabelLogoUri'] = ApiClient.convertToType(data['whiteLabelLogoUri'], 'String');
            }
            if (data.hasOwnProperty('noReplyEmail')) {
                obj['noReplyEmail'] = ApiClient.convertToType(data['noReplyEmail'], 'String');
            }
            if (data.hasOwnProperty('lastLogin')) {
                obj['lastLogin'] = ApiClient.convertToType(data['lastLogin'], 'Date');
            }
            if (data.hasOwnProperty('timeZone')) {
                obj['timeZone'] = ApiClient.convertToType(data['timeZone'], 'String');
            }
            if (data.hasOwnProperty('countryCode')) {
                obj['countryCode'] = ApiClient.convertToType(data['countryCode'], 'String');
            }
            if (data.hasOwnProperty('timeZoneId')) {
                obj['timeZoneId'] = ApiClient.convertToType(data['timeZoneId'], 'String');
            }
            if (data.hasOwnProperty('dateFormat')) {
                obj['dateFormat'] = ApiClient.convertToType(data['dateFormat'], 'String');
            }
            if (data.hasOwnProperty('emailPolicy')) {
                obj['emailPolicy'] = ApiClient.convertToType(data['emailPolicy'], 'Number');
            }
            if (data.hasOwnProperty('timeZoneOffSet')) {
                obj['timeZoneOffSet'] = ApiClient.convertToType(data['timeZoneOffSet'], 'Number');
            }
            if (data.hasOwnProperty('currentSubscriptionSku')) {
                obj['currentSubscriptionSku'] = ApiClient.convertToType(data['currentSubscriptionSku'], 'String');
            }
            if (data.hasOwnProperty('subscriptionState')) {
                obj['subscriptionState'] = SubscriptionState.constructFromObject(data['subscriptionState']);
            }
            if (data.hasOwnProperty('banners')) {
                obj['banners'] = ApiClient.convertToType(data['banners'], [AccountBannerItem]);
            }
            if (data.hasOwnProperty('subscription')) {
                obj['subscription'] = Subscription.constructFromObject(data['subscription']);
            }
            if (data.hasOwnProperty('accountProofProperties')) {
                obj['accountProofProperties'] = ApiClient.convertToType(data['accountProofProperties'], [AccountProofProperty]);
            }
            if (data.hasOwnProperty('emailSettings')) {
                obj['emailSettings'] = ApiClient.convertToType(data['emailSettings'], [EmailSendData]);
            }
            if (data.hasOwnProperty('usersTeams')) {
                obj['usersTeams'] = ApiClient.convertToType(data['usersTeams'], [AshoreUsersTeam]);
            }
            if (data.hasOwnProperty('accountRoles')) {
                obj['accountRoles'] = ApiClient.convertToType(data['accountRoles'], [AshoreUserRole]);
            }
            if (data.hasOwnProperty('themeSettings')) {
                obj['themeSettings'] = AccountThemeSettings.constructFromObject(data['themeSettings']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Account.prototype['id'] = undefined;

/**
 * @member {Number} resellerId
 */
Account.prototype['resellerId'] = undefined;

/**
 * @member {String} name
 */
Account.prototype['name'] = undefined;

/**
 * @member {String} whiteLabelDomainName
 */
Account.prototype['whiteLabelDomainName'] = undefined;

/**
 * @member {String} whiteLabelLogoUri
 */
Account.prototype['whiteLabelLogoUri'] = undefined;

/**
 * @member {String} noReplyEmail
 */
Account.prototype['noReplyEmail'] = undefined;

/**
 * @member {Date} lastLogin
 */
Account.prototype['lastLogin'] = undefined;

/**
 * @member {String} timeZone
 */
Account.prototype['timeZone'] = undefined;

/**
 * @member {String} countryCode
 */
Account.prototype['countryCode'] = undefined;

/**
 * @member {String} timeZoneId
 */
Account.prototype['timeZoneId'] = undefined;

/**
 * @member {String} dateFormat
 */
Account.prototype['dateFormat'] = undefined;

/**
 * @member {Number} emailPolicy
 */
Account.prototype['emailPolicy'] = undefined;

/**
 * @member {Number} timeZoneOffSet
 */
Account.prototype['timeZoneOffSet'] = undefined;

/**
 * @member {String} currentSubscriptionSku
 */
Account.prototype['currentSubscriptionSku'] = undefined;

/**
 * @member {module:model/SubscriptionState} subscriptionState
 */
Account.prototype['subscriptionState'] = undefined;

/**
 * @member {Array.<module:model/AccountBannerItem>} banners
 */
Account.prototype['banners'] = undefined;

/**
 * @member {module:model/Subscription} subscription
 */
Account.prototype['subscription'] = undefined;

/**
 * @member {Array.<module:model/AccountProofProperty>} accountProofProperties
 */
Account.prototype['accountProofProperties'] = undefined;

/**
 * @member {Array.<module:model/EmailSendData>} emailSettings
 */
Account.prototype['emailSettings'] = undefined;

/**
 * @member {Array.<module:model/AshoreUsersTeam>} usersTeams
 */
Account.prototype['usersTeams'] = undefined;

/**
 * @member {Array.<module:model/AshoreUserRole>} accountRoles
 */
Account.prototype['accountRoles'] = undefined;

/**
 * @member {module:model/AccountThemeSettings} themeSettings
 */
Account.prototype['themeSettings'] = undefined;






export default Account;

