/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The WebHookDeliveryAttempt model module.
 * @module model/WebHookDeliveryAttempt
 * @version V1
 */
class WebHookDeliveryAttempt {
    /**
     * Constructs a new <code>WebHookDeliveryAttempt</code>.
     * @alias module:model/WebHookDeliveryAttempt
     */
    constructor() { 
        
        WebHookDeliveryAttempt.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>WebHookDeliveryAttempt</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/WebHookDeliveryAttempt} obj Optional instance to populate.
     * @return {module:model/WebHookDeliveryAttempt} The populated <code>WebHookDeliveryAttempt</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WebHookDeliveryAttempt();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('webHookId')) {
                obj['webHookId'] = ApiClient.convertToType(data['webHookId'], 'Number');
            }
            if (data.hasOwnProperty('webHookEventId')) {
                obj['webHookEventId'] = ApiClient.convertToType(data['webHookEventId'], 'Number');
            }
            if (data.hasOwnProperty('attemptedAt')) {
                obj['attemptedAt'] = ApiClient.convertToType(data['attemptedAt'], 'Date');
            }
            if (data.hasOwnProperty('nextAttemptAt')) {
                obj['nextAttemptAt'] = ApiClient.convertToType(data['nextAttemptAt'], 'Date');
            }
            if (data.hasOwnProperty('sentAt')) {
                obj['sentAt'] = ApiClient.convertToType(data['sentAt'], 'Date');
            }
            if (data.hasOwnProperty('error')) {
                obj['error'] = ApiClient.convertToType(data['error'], 'String');
            }
            if (data.hasOwnProperty('responseHeaders')) {
                obj['responseHeaders'] = ApiClient.convertToType(data['responseHeaders'], {'String': 'String'});
            }
            if (data.hasOwnProperty('responseBody')) {
                obj['responseBody'] = ApiClient.convertToType(data['responseBody'], 'String');
            }
            if (data.hasOwnProperty('responseContentType')) {
                obj['responseContentType'] = ApiClient.convertToType(data['responseContentType'], 'String');
            }
            if (data.hasOwnProperty('requestHeaders')) {
                obj['requestHeaders'] = ApiClient.convertToType(data['requestHeaders'], {'String': 'String'});
            }
            if (data.hasOwnProperty('requestBody')) {
                obj['requestBody'] = ApiClient.convertToType(data['requestBody'], 'String');
            }
            if (data.hasOwnProperty('requestContentType')) {
                obj['requestContentType'] = ApiClient.convertToType(data['requestContentType'], 'String');
            }
            if (data.hasOwnProperty('statusCode')) {
                obj['statusCode'] = ApiClient.convertToType(data['statusCode'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
WebHookDeliveryAttempt.prototype['id'] = undefined;

/**
 * @member {Number} webHookId
 */
WebHookDeliveryAttempt.prototype['webHookId'] = undefined;

/**
 * @member {Number} webHookEventId
 */
WebHookDeliveryAttempt.prototype['webHookEventId'] = undefined;

/**
 * @member {Date} attemptedAt
 */
WebHookDeliveryAttempt.prototype['attemptedAt'] = undefined;

/**
 * @member {Date} nextAttemptAt
 */
WebHookDeliveryAttempt.prototype['nextAttemptAt'] = undefined;

/**
 * @member {Date} sentAt
 */
WebHookDeliveryAttempt.prototype['sentAt'] = undefined;

/**
 * @member {String} error
 */
WebHookDeliveryAttempt.prototype['error'] = undefined;

/**
 * @member {Object.<String, String>} responseHeaders
 */
WebHookDeliveryAttempt.prototype['responseHeaders'] = undefined;

/**
 * @member {String} responseBody
 */
WebHookDeliveryAttempt.prototype['responseBody'] = undefined;

/**
 * @member {String} responseContentType
 */
WebHookDeliveryAttempt.prototype['responseContentType'] = undefined;

/**
 * @member {Object.<String, String>} requestHeaders
 */
WebHookDeliveryAttempt.prototype['requestHeaders'] = undefined;

/**
 * @member {String} requestBody
 */
WebHookDeliveryAttempt.prototype['requestBody'] = undefined;

/**
 * @member {String} requestContentType
 */
WebHookDeliveryAttempt.prototype['requestContentType'] = undefined;

/**
 * @member {Number} statusCode
 */
WebHookDeliveryAttempt.prototype['statusCode'] = undefined;






export default WebHookDeliveryAttempt;

