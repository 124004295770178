/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import Approver from '../model/Approver';
import CreateEventRequest from '../model/CreateEventRequest';
import ProblemDetails from '../model/ProblemDetails';
import Proof from '../model/Proof';
import ProofApproverResponse from '../model/ProofApproverResponse';
import PublicAccountInfo from '../model/PublicAccountInfo';
import RegisterPublicApprover from '../model/RegisterPublicApprover';
import SuccessResponse from '../model/SuccessResponse';

/**
* Review service.
* @module api/ReviewApi
* @version V1
*/
export default class ReviewApi {

    /**
    * Constructs a new ReviewApi. 
    * @alias module:api/ReviewApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getAgencyReviewScreenLink operation.
     * @callback module:api/ReviewApi~getAgencyReviewScreenLinkCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {module:api/ReviewApi~getAgencyReviewScreenLinkCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getAgencyReviewScreenLink(proofId, callback) {
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling getAgencyReviewScreenLink");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/review/proof/agency/{proofId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getOwnerOfProof operation.
     * @callback module:api/ReviewApi~getOwnerOfProofCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PublicAccountInfo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {module:api/ReviewApi~getOwnerOfProofCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PublicAccountInfo}
     */
    getOwnerOfProof(publicProofId, callback) {
      let postBody = null;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling getOwnerOfProof");
      }

      let pathParams = {
        'publicProofId': publicProofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PublicAccountInfo;
      return this.apiClient.callApi(
        '/review/{publicProofId}/get-owner', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProofToReview operation.
     * @callback module:api/ReviewApi~getProofToReviewCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofVersionId 
     * @param {module:api/ReviewApi~getProofToReviewCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    getProofToReview(proofVersionId, callback) {
      let postBody = null;
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling getProofToReview");
      }

      let pathParams = {
        'proofVersionId': proofVersionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/review/proof/{proofVersionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the loadApproverByPublicId operation.
     * @callback module:api/ReviewApi~loadApproverByPublicIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Approver} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {String} publicApproverId 
     * @param {String} timeZone 
     * @param {module:api/ReviewApi~loadApproverByPublicIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Approver}
     */
    loadApproverByPublicId(publicProofId, publicApproverId, timeZone, callback) {
      let postBody = null;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling loadApproverByPublicId");
      }
      // verify the required parameter 'publicApproverId' is set
      if (publicApproverId === undefined || publicApproverId === null) {
        throw new Error("Missing the required parameter 'publicApproverId' when calling loadApproverByPublicId");
      }
      // verify the required parameter 'timeZone' is set
      if (timeZone === undefined || timeZone === null) {
        throw new Error("Missing the required parameter 'timeZone' when calling loadApproverByPublicId");
      }

      let pathParams = {
        'publicProofId': publicProofId,
        'publicApproverId': publicApproverId,
        'timeZone': timeZone
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Approver;
      return this.apiClient.callApi(
        '/review/approver/load/{publicProofId}/{publicApproverId}/{timeZone}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the markTourCompleted operation.
     * @callback module:api/ReviewApi~markTourCompletedCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ReviewApi~markTourCompletedCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    markTourCompleted(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/review/tour/mark-completed', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the proofClosed operation.
     * @callback module:api/ReviewApi~proofClosedCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {module:api/ReviewApi~proofClosedCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    proofClosed(publicProofId, callback) {
      let postBody = null;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling proofClosed");
      }

      let pathParams = {
        'publicProofId': publicProofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/review/proof-closed/{publicProofId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the recordApproverResponse operation.
     * @callback module:api/ReviewApi~recordApproverResponseCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ProofApproverResponse} opts.proofApproverResponse 
     * @param {module:api/ReviewApi~recordApproverResponseCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    recordApproverResponse(opts, callback) {
      opts = opts || {};
      let postBody = opts['proofApproverResponse'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/review/record-response', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the recordApproverViewEvent operation.
     * @callback module:api/ReviewApi~recordApproverViewEventCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ReviewApi~recordApproverViewEventCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    recordApproverViewEvent(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/review/proof/viewed', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the recordEvent operation.
     * @callback module:api/ReviewApi~recordEventCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateEventRequest} opts.createEventRequest 
     * @param {module:api/ReviewApi~recordEventCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    recordEvent(publicProofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['createEventRequest'];
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling recordEvent");
      }

      let pathParams = {
        'publicProofId': publicProofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/review/record-event/{publicProofId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the registerPublicApprover operation.
     * @callback module:api/ReviewApi~registerPublicApproverCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Approver} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {Object} opts Optional parameters
     * @param {module:model/RegisterPublicApprover} opts.registerPublicApprover 
     * @param {module:api/ReviewApi~registerPublicApproverCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Approver}
     */
    registerPublicApprover(publicProofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['registerPublicApprover'];
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling registerPublicApprover");
      }

      let pathParams = {
        'publicProofId': publicProofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Approver;
      return this.apiClient.callApi(
        '/review/approver/register/{publicProofId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the whoAmi operation.
     * @callback module:api/ReviewApi~whoAmiCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ReviewApi~whoAmiCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    whoAmi(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/review/who-am-i', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
