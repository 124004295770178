/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AccountChartCategoryData model module.
 * @module model/AccountChartCategoryData
 * @version V1
 */
class AccountChartCategoryData {
    /**
     * Constructs a new <code>AccountChartCategoryData</code>.
     * @alias module:model/AccountChartCategoryData
     */
    constructor() { 
        
        AccountChartCategoryData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountChartCategoryData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountChartCategoryData} obj Optional instance to populate.
     * @return {module:model/AccountChartCategoryData} The populated <code>AccountChartCategoryData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountChartCategoryData();

            if (data.hasOwnProperty('monthYearLabel')) {
                obj['monthYearLabel'] = ApiClient.convertToType(data['monthYearLabel'], 'String');
            }
            if (data.hasOwnProperty('firstOfTheMonthDate')) {
                obj['firstOfTheMonthDate'] = ApiClient.convertToType(data['firstOfTheMonthDate'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {String} monthYearLabel
 */
AccountChartCategoryData.prototype['monthYearLabel'] = undefined;

/**
 * @member {Date} firstOfTheMonthDate
 */
AccountChartCategoryData.prototype['firstOfTheMonthDate'] = undefined;






export default AccountChartCategoryData;

