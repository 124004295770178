/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SubscriptionChangeRequest model module.
 * @module model/SubscriptionChangeRequest
 * @version V1
 */
class SubscriptionChangeRequest {
    /**
     * Constructs a new <code>SubscriptionChangeRequest</code>.
     * @alias module:model/SubscriptionChangeRequest
     */
    constructor() { 
        
        SubscriptionChangeRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SubscriptionChangeRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SubscriptionChangeRequest} obj Optional instance to populate.
     * @return {module:model/SubscriptionChangeRequest} The populated <code>SubscriptionChangeRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubscriptionChangeRequest();

            if (data.hasOwnProperty('planSku')) {
                obj['planSku'] = ApiClient.convertToType(data['planSku'], 'String');
            }
            if (data.hasOwnProperty('couponCode')) {
                obj['couponCode'] = ApiClient.convertToType(data['couponCode'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} planSku
 */
SubscriptionChangeRequest.prototype['planSku'] = undefined;

/**
 * @member {String} couponCode
 */
SubscriptionChangeRequest.prototype['couponCode'] = undefined;






export default SubscriptionChangeRequest;

