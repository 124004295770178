/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ActionContextBooleanFunc from './ActionContextBooleanFunc';
import BindingSource from './BindingSource';
import EmptyBodyBehavior from './EmptyBodyBehavior';
import IPropertyFilterProvider from './IPropertyFilterProvider';

/**
 * The BindingInfo model module.
 * @module model/BindingInfo
 * @version V1
 */
class BindingInfo {
    /**
     * Constructs a new <code>BindingInfo</code>.
     * @alias module:model/BindingInfo
     */
    constructor() { 
        
        BindingInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BindingInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BindingInfo} obj Optional instance to populate.
     * @return {module:model/BindingInfo} The populated <code>BindingInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BindingInfo();

            if (data.hasOwnProperty('bindingSource')) {
                obj['bindingSource'] = BindingSource.constructFromObject(data['bindingSource']);
            }
            if (data.hasOwnProperty('binderModelName')) {
                obj['binderModelName'] = ApiClient.convertToType(data['binderModelName'], 'String');
            }
            if (data.hasOwnProperty('binderType')) {
                obj['binderType'] = ApiClient.convertToType(data['binderType'], 'String');
            }
            if (data.hasOwnProperty('propertyFilterProvider')) {
                obj['propertyFilterProvider'] = IPropertyFilterProvider.constructFromObject(data['propertyFilterProvider']);
            }
            if (data.hasOwnProperty('requestPredicate')) {
                obj['requestPredicate'] = ActionContextBooleanFunc.constructFromObject(data['requestPredicate']);
            }
            if (data.hasOwnProperty('emptyBodyBehavior')) {
                obj['emptyBodyBehavior'] = EmptyBodyBehavior.constructFromObject(data['emptyBodyBehavior']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/BindingSource} bindingSource
 */
BindingInfo.prototype['bindingSource'] = undefined;

/**
 * @member {String} binderModelName
 */
BindingInfo.prototype['binderModelName'] = undefined;

/**
 * @member {String} binderType
 */
BindingInfo.prototype['binderType'] = undefined;

/**
 * @member {module:model/IPropertyFilterProvider} propertyFilterProvider
 */
BindingInfo.prototype['propertyFilterProvider'] = undefined;

/**
 * @member {module:model/ActionContextBooleanFunc} requestPredicate
 */
BindingInfo.prototype['requestPredicate'] = undefined;

/**
 * @member {module:model/EmptyBodyBehavior} emptyBodyBehavior
 */
BindingInfo.prototype['emptyBodyBehavior'] = undefined;






export default BindingInfo;

