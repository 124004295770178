/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomAttributeData from './CustomAttributeData';
import MemberTypes from './MemberTypes';
import Module from './Module';

/**
 * The MemberInfo model module.
 * @module model/MemberInfo
 * @version V1
 */
class MemberInfo {
    /**
     * Constructs a new <code>MemberInfo</code>.
     * @alias module:model/MemberInfo
     */
    constructor() { 
        
        MemberInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MemberInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberInfo} obj Optional instance to populate.
     * @return {module:model/MemberInfo} The populated <code>MemberInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberInfo();

            if (data.hasOwnProperty('memberType')) {
                obj['memberType'] = MemberTypes.constructFromObject(data['memberType']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('declaringType')) {
                obj['declaringType'] = ApiClient.convertToType(data['declaringType'], 'String');
            }
            if (data.hasOwnProperty('reflectedType')) {
                obj['reflectedType'] = ApiClient.convertToType(data['reflectedType'], 'String');
            }
            if (data.hasOwnProperty('module')) {
                obj['module'] = Module.constructFromObject(data['module']);
            }
            if (data.hasOwnProperty('customAttributes')) {
                obj['customAttributes'] = ApiClient.convertToType(data['customAttributes'], [CustomAttributeData]);
            }
            if (data.hasOwnProperty('isCollectible')) {
                obj['isCollectible'] = ApiClient.convertToType(data['isCollectible'], 'Boolean');
            }
            if (data.hasOwnProperty('metadataToken')) {
                obj['metadataToken'] = ApiClient.convertToType(data['metadataToken'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/MemberTypes} memberType
 */
MemberInfo.prototype['memberType'] = undefined;

/**
 * @member {String} name
 */
MemberInfo.prototype['name'] = undefined;

/**
 * @member {String} declaringType
 */
MemberInfo.prototype['declaringType'] = undefined;

/**
 * @member {String} reflectedType
 */
MemberInfo.prototype['reflectedType'] = undefined;

/**
 * @member {module:model/Module} module
 */
MemberInfo.prototype['module'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeData>} customAttributes
 */
MemberInfo.prototype['customAttributes'] = undefined;

/**
 * @member {Boolean} isCollectible
 */
MemberInfo.prototype['isCollectible'] = undefined;

/**
 * @member {Number} metadataToken
 */
MemberInfo.prototype['metadataToken'] = undefined;






export default MemberInfo;

