/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import ApiToken from '../model/ApiToken';
import AuthTokenRefreshedResponse from '../model/AuthTokenRefreshedResponse';
import LoginRequest from '../model/LoginRequest';
import ProblemDetails from '../model/ProblemDetails';
import TokenRefreshRequest from '../model/TokenRefreshRequest';
import User from '../model/User';

/**
* Authentication service.
* @module api/AuthenticationApi
* @version V1
*/
export default class AuthenticationApi {

    /**
    * Constructs a new AuthenticationApi. 
    * @alias module:api/AuthenticationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getTempToken operation.
     * @callback module:api/AuthenticationApi~getTempTokenCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AuthTokenRefreshedResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AuthenticationApi~getTempTokenCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AuthTokenRefreshedResponse}
     */
    getTempToken(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AuthTokenRefreshedResponse;
      return this.apiClient.callApi(
        '/token/get-temp-token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the handleGoogleSso operation.
     * @callback module:api/AuthenticationApi~handleGoogleSsoCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} ssoProvider 
     * @param {module:api/AuthenticationApi~handleGoogleSsoCallback} callback The callback function, accepting three arguments: error, data, response
     */
    handleGoogleSso(ssoProvider, callback) {
      let postBody = null;
      // verify the required parameter 'ssoProvider' is set
      if (ssoProvider === undefined || ssoProvider === null) {
        throw new Error("Missing the required parameter 'ssoProvider' when calling handleGoogleSso");
      }

      let pathParams = {
        'ssoProvider': ssoProvider
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/login/sso/{ssoProvider}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the login operation.
     * @callback module:api/AuthenticationApi~loginCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/LoginRequest} opts.loginRequest 
     * @param {module:api/AuthenticationApi~loginCallback} callback The callback function, accepting three arguments: error, data, response
     */
    login(opts, callback) {
      opts = opts || {};
      let postBody = opts['loginRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the loginWithToken operation.
     * @callback module:api/AuthenticationApi~loginWithTokenCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AuthenticationApi~loginWithTokenCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    loginWithToken(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/token/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the logout operation.
     * @callback module:api/AuthenticationApi~logoutCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AuthenticationApi~logoutCallback} callback The callback function, accepting three arguments: error, data, response
     */
    logout(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the logoutSso operation.
     * @callback module:api/AuthenticationApi~logoutSsoCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AuthenticationApi~logoutSsoCallback} callback The callback function, accepting three arguments: error, data, response
     */
    logoutSso(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/logout', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the refreshExpiredToken operation.
     * @callback module:api/AuthenticationApi~refreshExpiredTokenCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AuthTokenRefreshedResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/TokenRefreshRequest} opts.tokenRefreshRequest 
     * @param {module:api/AuthenticationApi~refreshExpiredTokenCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AuthTokenRefreshedResponse}
     */
    refreshExpiredToken(opts, callback) {
      opts = opts || {};
      let postBody = opts['tokenRefreshRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AuthTokenRefreshedResponse;
      return this.apiClient.callApi(
        '/token/refresh', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tokenIsValid operation.
     * @callback module:api/AuthenticationApi~tokenIsValidCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiToken} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AuthenticationApi~tokenIsValidCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiToken}
     */
    tokenIsValid(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ApiToken;
      return this.apiClient.callApi(
        '/token/me', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
