import EnvService from "../env.settings";
import { isSupported, setup } from "@loomhq/loom-sdk";

class LoomService {
    constructor() {
        this.init();
    }

    async init() {
        let supportedResponse = await isSupported();
        if (supportedResponse.error) {
            console.log("loom error", supportedResponse)
            return;
        }
        this.supported = supportedResponse.supported;
        if (this.supported) {
            const setupResponse = await setup({ apiKey: EnvService.instance.loomKey });
            if (!setupResponse.status || !setupResponse.status().success) {
                return;
            }
            this.configureButton = setupResponse.configureButton;
            if (this.configureButton) {
                this.isLoomAvailable = true;
            }
        }
    }

    configureSdkButton() {
        return this.configureButton({});
    }
}

export default LoomService;