/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomAttributeData from './CustomAttributeData';
import MemberInfo from './MemberInfo';
import ParameterAttributes from './ParameterAttributes';

/**
 * The ParameterInfo model module.
 * @module model/ParameterInfo
 * @version V1
 */
class ParameterInfo {
    /**
     * Constructs a new <code>ParameterInfo</code>.
     * @alias module:model/ParameterInfo
     */
    constructor() { 
        
        ParameterInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ParameterInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ParameterInfo} obj Optional instance to populate.
     * @return {module:model/ParameterInfo} The populated <code>ParameterInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParameterInfo();

            if (data.hasOwnProperty('attributes')) {
                obj['attributes'] = ParameterAttributes.constructFromObject(data['attributes']);
            }
            if (data.hasOwnProperty('member')) {
                obj['member'] = MemberInfo.constructFromObject(data['member']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('parameterType')) {
                obj['parameterType'] = ApiClient.convertToType(data['parameterType'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('isIn')) {
                obj['isIn'] = ApiClient.convertToType(data['isIn'], 'Boolean');
            }
            if (data.hasOwnProperty('isLcid')) {
                obj['isLcid'] = ApiClient.convertToType(data['isLcid'], 'Boolean');
            }
            if (data.hasOwnProperty('isOptional')) {
                obj['isOptional'] = ApiClient.convertToType(data['isOptional'], 'Boolean');
            }
            if (data.hasOwnProperty('isOut')) {
                obj['isOut'] = ApiClient.convertToType(data['isOut'], 'Boolean');
            }
            if (data.hasOwnProperty('isRetval')) {
                obj['isRetval'] = ApiClient.convertToType(data['isRetval'], 'Boolean');
            }
            if (data.hasOwnProperty('defaultValue')) {
                obj['defaultValue'] = ApiClient.convertToType(data['defaultValue'], Object);
            }
            if (data.hasOwnProperty('rawDefaultValue')) {
                obj['rawDefaultValue'] = ApiClient.convertToType(data['rawDefaultValue'], Object);
            }
            if (data.hasOwnProperty('hasDefaultValue')) {
                obj['hasDefaultValue'] = ApiClient.convertToType(data['hasDefaultValue'], 'Boolean');
            }
            if (data.hasOwnProperty('customAttributes')) {
                obj['customAttributes'] = ApiClient.convertToType(data['customAttributes'], [CustomAttributeData]);
            }
            if (data.hasOwnProperty('metadataToken')) {
                obj['metadataToken'] = ApiClient.convertToType(data['metadataToken'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/ParameterAttributes} attributes
 */
ParameterInfo.prototype['attributes'] = undefined;

/**
 * @member {module:model/MemberInfo} member
 */
ParameterInfo.prototype['member'] = undefined;

/**
 * @member {String} name
 */
ParameterInfo.prototype['name'] = undefined;

/**
 * @member {String} parameterType
 */
ParameterInfo.prototype['parameterType'] = undefined;

/**
 * @member {Number} position
 */
ParameterInfo.prototype['position'] = undefined;

/**
 * @member {Boolean} isIn
 */
ParameterInfo.prototype['isIn'] = undefined;

/**
 * @member {Boolean} isLcid
 */
ParameterInfo.prototype['isLcid'] = undefined;

/**
 * @member {Boolean} isOptional
 */
ParameterInfo.prototype['isOptional'] = undefined;

/**
 * @member {Boolean} isOut
 */
ParameterInfo.prototype['isOut'] = undefined;

/**
 * @member {Boolean} isRetval
 */
ParameterInfo.prototype['isRetval'] = undefined;

/**
 * @member {Object} defaultValue
 */
ParameterInfo.prototype['defaultValue'] = undefined;

/**
 * @member {Object} rawDefaultValue
 */
ParameterInfo.prototype['rawDefaultValue'] = undefined;

/**
 * @member {Boolean} hasDefaultValue
 */
ParameterInfo.prototype['hasDefaultValue'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeData>} customAttributes
 */
ParameterInfo.prototype['customAttributes'] = undefined;

/**
 * @member {Number} metadataToken
 */
ParameterInfo.prototype['metadataToken'] = undefined;






export default ParameterInfo;

