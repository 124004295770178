/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AshoreTeamShortInfo model module.
 * @module model/AshoreTeamShortInfo
 * @version V1
 */
class AshoreTeamShortInfo {
    /**
     * Constructs a new <code>AshoreTeamShortInfo</code>.
     * @alias module:model/AshoreTeamShortInfo
     */
    constructor() { 
        
        AshoreTeamShortInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AshoreTeamShortInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AshoreTeamShortInfo} obj Optional instance to populate.
     * @return {module:model/AshoreTeamShortInfo} The populated <code>AshoreTeamShortInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AshoreTeamShortInfo();

            if (data.hasOwnProperty('teamId')) {
                obj['teamId'] = ApiClient.convertToType(data['teamId'], 'Number');
            }
            if (data.hasOwnProperty('isDefault')) {
                obj['isDefault'] = ApiClient.convertToType(data['isDefault'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} teamId
 */
AshoreTeamShortInfo.prototype['teamId'] = undefined;

/**
 * @member {Boolean} isDefault
 */
AshoreTeamShortInfo.prototype['isDefault'] = undefined;






export default AshoreTeamShortInfo;

