/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AttributeRouteInfo from './AttributeRouteInfo';
import FilterDescriptor from './FilterDescriptor';
import ParameterDescriptor from './ParameterDescriptor';

/**
 * The ActionDescriptor model module.
 * @module model/ActionDescriptor
 * @version V1
 */
class ActionDescriptor {
    /**
     * Constructs a new <code>ActionDescriptor</code>.
     * @alias module:model/ActionDescriptor
     */
    constructor() { 
        
        ActionDescriptor.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ActionDescriptor</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ActionDescriptor} obj Optional instance to populate.
     * @return {module:model/ActionDescriptor} The populated <code>ActionDescriptor</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ActionDescriptor();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('routeValues')) {
                obj['routeValues'] = ApiClient.convertToType(data['routeValues'], {'String': 'String'});
            }
            if (data.hasOwnProperty('attributeRouteInfo')) {
                obj['attributeRouteInfo'] = AttributeRouteInfo.constructFromObject(data['attributeRouteInfo']);
            }
            if (data.hasOwnProperty('actionConstraints')) {
                obj['actionConstraints'] = ApiClient.convertToType(data['actionConstraints'], [Object]);
            }
            if (data.hasOwnProperty('endpointMetadata')) {
                obj['endpointMetadata'] = ApiClient.convertToType(data['endpointMetadata'], [Object]);
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], [ParameterDescriptor]);
            }
            if (data.hasOwnProperty('boundProperties')) {
                obj['boundProperties'] = ApiClient.convertToType(data['boundProperties'], [ParameterDescriptor]);
            }
            if (data.hasOwnProperty('filterDescriptors')) {
                obj['filterDescriptors'] = ApiClient.convertToType(data['filterDescriptors'], [FilterDescriptor]);
            }
            if (data.hasOwnProperty('displayName')) {
                obj['displayName'] = ApiClient.convertToType(data['displayName'], 'String');
            }
            if (data.hasOwnProperty('properties')) {
                obj['properties'] = ApiClient.convertToType(data['properties'], {'String': Object});
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
ActionDescriptor.prototype['id'] = undefined;

/**
 * @member {Object.<String, String>} routeValues
 */
ActionDescriptor.prototype['routeValues'] = undefined;

/**
 * @member {module:model/AttributeRouteInfo} attributeRouteInfo
 */
ActionDescriptor.prototype['attributeRouteInfo'] = undefined;

/**
 * @member {Array.<Object>} actionConstraints
 */
ActionDescriptor.prototype['actionConstraints'] = undefined;

/**
 * @member {Array.<Object>} endpointMetadata
 */
ActionDescriptor.prototype['endpointMetadata'] = undefined;

/**
 * @member {Array.<module:model/ParameterDescriptor>} parameters
 */
ActionDescriptor.prototype['parameters'] = undefined;

/**
 * @member {Array.<module:model/ParameterDescriptor>} boundProperties
 */
ActionDescriptor.prototype['boundProperties'] = undefined;

/**
 * @member {Array.<module:model/FilterDescriptor>} filterDescriptors
 */
ActionDescriptor.prototype['filterDescriptors'] = undefined;

/**
 * @member {String} displayName
 */
ActionDescriptor.prototype['displayName'] = undefined;

/**
 * @member {Object.<String, Object>} properties
 */
ActionDescriptor.prototype['properties'] = undefined;






export default ActionDescriptor;

