/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import ArchiveProofRequest from '../model/ArchiveProofRequest';
import CreateNewProofVersionRequest from '../model/CreateNewProofVersionRequest';
import GetMultipleProofsRequest from '../model/GetMultipleProofsRequest';
import Proof from '../model/Proof';
import ProofCustomField from '../model/ProofCustomField';
import ProofListItem from '../model/ProofListItem';
import ProofSearchOptions from '../model/ProofSearchOptions';
import ProofVersion from '../model/ProofVersion';
import SaveProofRequest from '../model/SaveProofRequest';
import SendMessageToCurrentApproversRequest from '../model/SendMessageToCurrentApproversRequest';
import SuccessResponse from '../model/SuccessResponse';
import UpdateApproverStatusRequest from '../model/UpdateApproverStatusRequest';

/**
* Proof service.
* @module api/ProofApi
* @version V1
*/
export default class ProofApi {

    /**
    * Constructs a new ProofApi. 
    * @alias module:api/ProofApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addNoteToProofTimeline operation.
     * @callback module:api/ProofApi~addNoteToProofTimelineCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/ProofApi~addNoteToProofTimelineCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    addNoteToProofTimeline(proofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling addNoteToProofTimeline");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/{proofId}/add-note-to-time-line', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the archiveProofsArchiveProofs operation.
     * @callback module:api/ProofApi~archiveProofsArchiveProofsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ArchiveProofRequest} opts.archiveProofRequest 
     * @param {module:api/ProofApi~archiveProofsArchiveProofsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    archiveProofsArchiveProofs(opts, callback) {
      opts = opts || {};
      let postBody = opts['archiveProofRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/archive', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the attachWorkflowToProof operation.
     * @callback module:api/ProofApi~attachWorkflowToProofCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} workflowId 
     * @param {module:api/ProofApi~attachWorkflowToProofCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    attachWorkflowToProof(proofId, workflowId, callback) {
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling attachWorkflowToProof");
      }
      // verify the required parameter 'workflowId' is set
      if (workflowId === undefined || workflowId === null) {
        throw new Error("Missing the required parameter 'workflowId' when calling attachWorkflowToProof");
      }

      let pathParams = {
        'proofId': proofId,
        'workflowId': workflowId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/{proofId}/attach-workflow/{workflowId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the changeProofVersionDisplay operation.
     * @callback module:api/ProofApi~changeProofVersionDisplayCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.hide 
     * @param {module:api/ProofApi~changeProofVersionDisplayCallback} callback The callback function, accepting three arguments: error, data, response
     */
    changeProofVersionDisplay(proofId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling changeProofVersionDisplay");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling changeProofVersionDisplay");
      }

      let pathParams = {
        'proofId': proofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
        'hide': opts['hide']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/proof/{proofId}/proof-version/{proofVersionId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createNewProof operation.
     * @callback module:api/ProofApi~createNewProofCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.proofCreatorUserId  (default to 0)
     * @param {module:model/SaveProofRequest} opts.saveProofRequest 
     * @param {module:api/ProofApi~createNewProofCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    createNewProof(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveProofRequest'];

      let pathParams = {
      };
      let queryParams = {
        'proofCreatorUserId': opts['proofCreatorUserId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/proof', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createNewVersion operation.
     * @callback module:api/ProofApi~createNewVersionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofVersion} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateNewProofVersionRequest} opts.createNewProofVersionRequest 
     * @param {module:api/ProofApi~createNewVersionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofVersion}
     */
    createNewVersion(opts, callback) {
      opts = opts || {};
      let postBody = opts['createNewProofVersionRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofVersion;
      return this.apiClient.callApi(
        '/proof/new-version', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createOrUpdateCustomFieldValue operation.
     * @callback module:api/ProofApi~createOrUpdateCustomFieldValueCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofCustomField} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProofCustomField} opts.proofCustomField 
     * @param {module:api/ProofApi~createOrUpdateCustomFieldValueCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofCustomField}
     */
    createOrUpdateCustomFieldValue(proofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['proofCustomField'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling createOrUpdateCustomFieldValue");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofCustomField;
      return this.apiClient.callApi(
        '/proof/{proofId}/custom-properties', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteProofs operation.
     * @callback module:api/ProofApi~deleteProofsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.requestBody 
     * @param {module:api/ProofApi~deleteProofsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteProofs(proofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling deleteProofs");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/{proofId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the exportProofToPdf operation.
     * @callback module:api/ProofApi~exportProofToPdfCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isDebug  (default to false)
     * @param {module:api/ProofApi~exportProofToPdfCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    exportProofToPdf(proofId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling exportProofToPdf");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
        'isDebug': opts['isDebug']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/proof/{proofId}/export-pdf', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getFullProof operation.
     * @callback module:api/ProofApi~getFullProofCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.loadAllVersions  (default to false)
     * @param {module:api/ProofApi~getFullProofCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    getFullProof(proofId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling getFullProof");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling getFullProof");
      }

      let pathParams = {
        'proofId': proofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
        'loadAllVersions': opts['loadAllVersions']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/proof/{proofId}/{proofVersionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getMultipleProofs operation.
     * @callback module:api/ProofApi~getMultipleProofsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get multiple proofs at the same time. Limit is 10
     * @param {Object} opts Optional parameters
     * @param {module:model/GetMultipleProofsRequest} opts.getMultipleProofsRequest 
     * @param {module:api/ProofApi~getMultipleProofsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    getMultipleProofs(opts, callback) {
      opts = opts || {};
      let postBody = opts['getMultipleProofsRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/proof/multiple', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProofFromPubId operation.
     * @callback module:api/ProofApi~getProofFromPubIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {module:api/ProofApi~getProofFromPubIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    getProofFromPubId(publicProofId, callback) {
      let postBody = null;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling getProofFromPubId");
      }

      let pathParams = {
        'publicProofId': publicProofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/proof/pub/{publicProofId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProofVersionThumbnail operation.
     * @callback module:api/ProofApi~getProofVersionThumbnailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofCustomField} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {String} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.version 
     * @param {module:api/ProofApi~getProofVersionThumbnailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofCustomField}
     */
    getProofVersionThumbnail(publicProofId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling getProofVersionThumbnail");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling getProofVersionThumbnail");
      }

      let pathParams = {
        'publicProofId': publicProofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
        'version': opts['version']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofCustomField;
      return this.apiClient.callApi(
        '/proof/{publicProofId}/thumbnail/{proofVersionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProofVersionThumbnail_0 operation.
     * @callback module:api/ProofApi~getProofVersionThumbnail_0Callback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofCustomField} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {String} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.version 
     * @param {module:api/ProofApi~getProofVersionThumbnail_0Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofCustomField}
     */
    getProofVersionThumbnail_0(publicProofId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling getProofVersionThumbnail_0");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling getProofVersionThumbnail_0");
      }

      let pathParams = {
        'publicProofId': publicProofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
        'version': opts['version']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofCustomField;
      return this.apiClient.callApi(
        '/api/proof/{publicProofId}/thumbnail/{proofVersionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getTotalProofsFromSearch operation.
     * @callback module:api/ProofApi~getTotalProofsFromSearchCallback
     * @param {String} error Error message, if any.
     * @param {Number} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ProofSearchOptions} opts.proofSearchOptions 
     * @param {module:api/ProofApi~getTotalProofsFromSearchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Number}
     */
    getTotalProofsFromSearch(opts, callback) {
      opts = opts || {};
      let postBody = opts['proofSearchOptions'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Number';
      return this.apiClient.callApi(
        '/proof/search/total', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the overRideApproverStatus operation.
     * @callback module:api/ProofApi~overRideApproverStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} approverId 
     * @param {Number} stageId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateApproverStatusRequest} opts.updateApproverStatusRequest 
     * @param {module:api/ProofApi~overRideApproverStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    overRideApproverStatus(proofId, approverId, stageId, opts, callback) {
      opts = opts || {};
      let postBody = opts['updateApproverStatusRequest'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling overRideApproverStatus");
      }
      // verify the required parameter 'approverId' is set
      if (approverId === undefined || approverId === null) {
        throw new Error("Missing the required parameter 'approverId' when calling overRideApproverStatus");
      }
      // verify the required parameter 'stageId' is set
      if (stageId === undefined || stageId === null) {
        throw new Error("Missing the required parameter 'stageId' when calling overRideApproverStatus");
      }

      let pathParams = {
        'proofId': proofId,
        'approverId': approverId,
        'stageId': stageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/proof/{proofId}/approver/{approverId}/stageId/{stageId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the searchProofs operation.
     * @callback module:api/ProofApi~searchProofsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProofListItem>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} page 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProofSearchOptions} opts.proofSearchOptions 
     * @param {module:api/ProofApi~searchProofsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProofListItem>}
     */
    searchProofs(page, opts, callback) {
      opts = opts || {};
      let postBody = opts['proofSearchOptions'];
      // verify the required parameter 'page' is set
      if (page === undefined || page === null) {
        throw new Error("Missing the required parameter 'page' when calling searchProofs");
      }

      let pathParams = {
        'page': page
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [ProofListItem];
      return this.apiClient.callApi(
        '/proof/search/{page}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the sendMessageToCurrentApprovers operation.
     * @callback module:api/ProofApi~sendMessageToCurrentApproversCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SendMessageToCurrentApproversRequest} opts.sendMessageToCurrentApproversRequest 
     * @param {module:api/ProofApi~sendMessageToCurrentApproversCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    sendMessageToCurrentApprovers(proofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['sendMessageToCurrentApproversRequest'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling sendMessageToCurrentApprovers");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/{proofId}/send-message-to-current-approvers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setCurrentSender operation.
     * @callback module:api/ProofApi~setCurrentSenderCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} userId 
     * @param {module:api/ProofApi~setCurrentSenderCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    setCurrentSender(proofId, userId, callback) {
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling setCurrentSender");
      }
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling setCurrentSender");
      }

      let pathParams = {
        'proofId': proofId,
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/proof/{proofId}/owner/{userId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the startWorkflowForProofVersion operation.
     * @callback module:api/ProofApi~startWorkflowForProofVersionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {module:api/ProofApi~startWorkflowForProofVersionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    startWorkflowForProofVersion(proofId, callback) {
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling startWorkflowForProofVersion");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/{proofId}/start-workflow-for-current-version', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the unArchiveProofs operation.
     * @callback module:api/ProofApi~unArchiveProofsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.requestBody 
     * @param {module:api/ProofApi~unArchiveProofsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    unArchiveProofs(proofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling unArchiveProofs");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/unarchive/{proofId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateProof operation.
     * @callback module:api/ProofApi~updateProofCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Proof} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveProofRequest} opts.saveProofRequest 
     * @param {module:api/ProofApi~updateProofCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Proof}
     */
    updateProof(proofId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveProofRequest'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling updateProof");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Proof;
      return this.apiClient.callApi(
        '/proof/{proofId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateWorkflowForCurrentVersion operation.
     * @callback module:api/ProofApi~updateWorkflowForCurrentVersionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {module:api/ProofApi~updateWorkflowForCurrentVersionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    updateWorkflowForCurrentVersion(proofId, callback) {
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling updateWorkflowForCurrentVersion");
      }

      let pathParams = {
        'proofId': proofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof/{proofId}/update-workflow-for-current-version', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
