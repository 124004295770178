/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProofFileMetadata model module.
 * @module model/ProofFileMetadata
 * @version V1
 */
class ProofFileMetadata {
    /**
     * Constructs a new <code>ProofFileMetadata</code>.
     * @alias module:model/ProofFileMetadata
     */
    constructor() { 
        
        ProofFileMetadata.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofFileMetadata</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofFileMetadata} obj Optional instance to populate.
     * @return {module:model/ProofFileMetadata} The populated <code>ProofFileMetadata</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofFileMetadata();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionFileId')) {
                obj['proofVersionFileId'] = ApiClient.convertToType(data['proofVersionFileId'], 'Number');
            }
            if (data.hasOwnProperty('fileMetadata')) {
                obj['fileMetadata'] = ApiClient.convertToType(data['fileMetadata'], {'String': 'String'});
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ProofFileMetadata.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
ProofFileMetadata.prototype['accountId'] = undefined;

/**
 * @member {Number} proofVersionFileId
 */
ProofFileMetadata.prototype['proofVersionFileId'] = undefined;

/**
 * @member {Object.<String, String>} fileMetadata
 */
ProofFileMetadata.prototype['fileMetadata'] = undefined;






export default ProofFileMetadata;

