/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The EmailAuthorization model module.
 * @module model/EmailAuthorization
 * @version V1
 */
class EmailAuthorization {
    /**
     * Constructs a new <code>EmailAuthorization</code>.
     * @alias module:model/EmailAuthorization
     */
    constructor() { 
        
        EmailAuthorization.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>EmailAuthorization</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/EmailAuthorization} obj Optional instance to populate.
     * @return {module:model/EmailAuthorization} The populated <code>EmailAuthorization</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EmailAuthorization();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('postMarkId')) {
                obj['postMarkId'] = ApiClient.convertToType(data['postMarkId'], 'Number');
            }
            if (data.hasOwnProperty('domain')) {
                obj['domain'] = ApiClient.convertToType(data['domain'], 'String');
            }
            if (data.hasOwnProperty('emailAddress')) {
                obj['emailAddress'] = ApiClient.convertToType(data['emailAddress'], 'String');
            }
            if (data.hasOwnProperty('replyToEmailAddress')) {
                obj['replyToEmailAddress'] = ApiClient.convertToType(data['replyToEmailAddress'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('enabled')) {
                obj['enabled'] = ApiClient.convertToType(data['enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('confirmed')) {
                obj['confirmed'] = ApiClient.convertToType(data['confirmed'], 'Boolean');
            }
            if (data.hasOwnProperty('spfVerified')) {
                obj['spfVerified'] = ApiClient.convertToType(data['spfVerified'], 'Boolean');
            }
            if (data.hasOwnProperty('spfHost')) {
                obj['spfHost'] = ApiClient.convertToType(data['spfHost'], 'String');
            }
            if (data.hasOwnProperty('spfTextValue')) {
                obj['spfTextValue'] = ApiClient.convertToType(data['spfTextValue'], 'String');
            }
            if (data.hasOwnProperty('dkimVerified')) {
                obj['dkimVerified'] = ApiClient.convertToType(data['dkimVerified'], 'Boolean');
            }
            if (data.hasOwnProperty('weakDKIM')) {
                obj['weakDKIM'] = ApiClient.convertToType(data['weakDKIM'], 'Boolean');
            }
            if (data.hasOwnProperty('dkimHost')) {
                obj['dkimHost'] = ApiClient.convertToType(data['dkimHost'], 'String');
            }
            if (data.hasOwnProperty('dkimTextValue')) {
                obj['dkimTextValue'] = ApiClient.convertToType(data['dkimTextValue'], 'String');
            }
            if (data.hasOwnProperty('dkimPendingHost')) {
                obj['dkimPendingHost'] = ApiClient.convertToType(data['dkimPendingHost'], 'String');
            }
            if (data.hasOwnProperty('dkimPendingTextValue')) {
                obj['dkimPendingTextValue'] = ApiClient.convertToType(data['dkimPendingTextValue'], 'String');
            }
            if (data.hasOwnProperty('dkimRevokedHost')) {
                obj['dkimRevokedHost'] = ApiClient.convertToType(data['dkimRevokedHost'], 'String');
            }
            if (data.hasOwnProperty('dkimRevokedTextValue')) {
                obj['dkimRevokedTextValue'] = ApiClient.convertToType(data['dkimRevokedTextValue'], 'String');
            }
            if (data.hasOwnProperty('dkimUpdateStatus')) {
                obj['dkimUpdateStatus'] = ApiClient.convertToType(data['dkimUpdateStatus'], 'String');
            }
            if (data.hasOwnProperty('returnPathDomain')) {
                obj['returnPathDomain'] = ApiClient.convertToType(data['returnPathDomain'], 'String');
            }
            if (data.hasOwnProperty('returnPathDomainVerified')) {
                obj['returnPathDomainVerified'] = ApiClient.convertToType(data['returnPathDomainVerified'], 'Boolean');
            }
            if (data.hasOwnProperty('returnPathDomainCNAMEValue')) {
                obj['returnPathDomainCNAMEValue'] = ApiClient.convertToType(data['returnPathDomainCNAMEValue'], 'String');
            }
            if (data.hasOwnProperty('safeToRemoveRevokedKeyFromDNS')) {
                obj['safeToRemoveRevokedKeyFromDNS'] = ApiClient.convertToType(data['safeToRemoveRevokedKeyFromDNS'], 'Boolean');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
EmailAuthorization.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
EmailAuthorization.prototype['accountId'] = undefined;

/**
 * @member {Number} userId
 */
EmailAuthorization.prototype['userId'] = undefined;

/**
 * @member {Number} postMarkId
 */
EmailAuthorization.prototype['postMarkId'] = undefined;

/**
 * @member {String} domain
 */
EmailAuthorization.prototype['domain'] = undefined;

/**
 * @member {String} emailAddress
 */
EmailAuthorization.prototype['emailAddress'] = undefined;

/**
 * @member {String} replyToEmailAddress
 */
EmailAuthorization.prototype['replyToEmailAddress'] = undefined;

/**
 * @member {String} name
 */
EmailAuthorization.prototype['name'] = undefined;

/**
 * @member {Boolean} enabled
 */
EmailAuthorization.prototype['enabled'] = undefined;

/**
 * @member {Boolean} confirmed
 */
EmailAuthorization.prototype['confirmed'] = undefined;

/**
 * @member {Boolean} spfVerified
 */
EmailAuthorization.prototype['spfVerified'] = undefined;

/**
 * @member {String} spfHost
 */
EmailAuthorization.prototype['spfHost'] = undefined;

/**
 * @member {String} spfTextValue
 */
EmailAuthorization.prototype['spfTextValue'] = undefined;

/**
 * @member {Boolean} dkimVerified
 */
EmailAuthorization.prototype['dkimVerified'] = undefined;

/**
 * @member {Boolean} weakDKIM
 */
EmailAuthorization.prototype['weakDKIM'] = undefined;

/**
 * @member {String} dkimHost
 */
EmailAuthorization.prototype['dkimHost'] = undefined;

/**
 * @member {String} dkimTextValue
 */
EmailAuthorization.prototype['dkimTextValue'] = undefined;

/**
 * @member {String} dkimPendingHost
 */
EmailAuthorization.prototype['dkimPendingHost'] = undefined;

/**
 * @member {String} dkimPendingTextValue
 */
EmailAuthorization.prototype['dkimPendingTextValue'] = undefined;

/**
 * @member {String} dkimRevokedHost
 */
EmailAuthorization.prototype['dkimRevokedHost'] = undefined;

/**
 * @member {String} dkimRevokedTextValue
 */
EmailAuthorization.prototype['dkimRevokedTextValue'] = undefined;

/**
 * @member {String} dkimUpdateStatus
 */
EmailAuthorization.prototype['dkimUpdateStatus'] = undefined;

/**
 * @member {String} returnPathDomain
 */
EmailAuthorization.prototype['returnPathDomain'] = undefined;

/**
 * @member {Boolean} returnPathDomainVerified
 */
EmailAuthorization.prototype['returnPathDomainVerified'] = undefined;

/**
 * @member {String} returnPathDomainCNAMEValue
 */
EmailAuthorization.prototype['returnPathDomainCNAMEValue'] = undefined;

/**
 * @member {Boolean} safeToRemoveRevokedKeyFromDNS
 */
EmailAuthorization.prototype['safeToRemoveRevokedKeyFromDNS'] = undefined;

/**
 * @member {Date} createdAt
 */
EmailAuthorization.prototype['createdAt'] = undefined;






export default EmailAuthorization;

