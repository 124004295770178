/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SmtpServerInfo from './SmtpServerInfo';

/**
 * The UpdateAccountRequest model module.
 * @module model/UpdateAccountRequest
 * @version V1
 */
class UpdateAccountRequest {
    /**
     * Constructs a new <code>UpdateAccountRequest</code>.
     * @alias module:model/UpdateAccountRequest
     */
    constructor() { 
        
        UpdateAccountRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UpdateAccountRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UpdateAccountRequest} obj Optional instance to populate.
     * @return {module:model/UpdateAccountRequest} The populated <code>UpdateAccountRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UpdateAccountRequest();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('noReplyEmail')) {
                obj['noReplyEmail'] = ApiClient.convertToType(data['noReplyEmail'], 'String');
            }
            if (data.hasOwnProperty('timeZone')) {
                obj['timeZone'] = ApiClient.convertToType(data['timeZone'], 'String');
            }
            if (data.hasOwnProperty('dateFormat')) {
                obj['dateFormat'] = ApiClient.convertToType(data['dateFormat'], 'String');
            }
            if (data.hasOwnProperty('whiteLabelDomainName')) {
                obj['whiteLabelDomainName'] = ApiClient.convertToType(data['whiteLabelDomainName'], 'String');
            }
            if (data.hasOwnProperty('smtpServerInfo')) {
                obj['smtpServerInfo'] = SmtpServerInfo.constructFromObject(data['smtpServerInfo']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
UpdateAccountRequest.prototype['name'] = undefined;

/**
 * @member {String} noReplyEmail
 */
UpdateAccountRequest.prototype['noReplyEmail'] = undefined;

/**
 * @member {String} timeZone
 */
UpdateAccountRequest.prototype['timeZone'] = undefined;

/**
 * @member {String} dateFormat
 */
UpdateAccountRequest.prototype['dateFormat'] = undefined;

/**
 * @member {String} whiteLabelDomainName
 */
UpdateAccountRequest.prototype['whiteLabelDomainName'] = undefined;

/**
 * @member {module:model/SmtpServerInfo} smtpServerInfo
 */
UpdateAccountRequest.prototype['smtpServerInfo'] = undefined;






export default UpdateAccountRequest;

