/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AttributeRouteInfo model module.
 * @module model/AttributeRouteInfo
 * @version V1
 */
class AttributeRouteInfo {
    /**
     * Constructs a new <code>AttributeRouteInfo</code>.
     * @alias module:model/AttributeRouteInfo
     */
    constructor() { 
        
        AttributeRouteInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AttributeRouteInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AttributeRouteInfo} obj Optional instance to populate.
     * @return {module:model/AttributeRouteInfo} The populated <code>AttributeRouteInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AttributeRouteInfo();

            if (data.hasOwnProperty('template')) {
                obj['template'] = ApiClient.convertToType(data['template'], 'String');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('suppressLinkGeneration')) {
                obj['suppressLinkGeneration'] = ApiClient.convertToType(data['suppressLinkGeneration'], 'Boolean');
            }
            if (data.hasOwnProperty('suppressPathMatching')) {
                obj['suppressPathMatching'] = ApiClient.convertToType(data['suppressPathMatching'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} template
 */
AttributeRouteInfo.prototype['template'] = undefined;

/**
 * @member {Number} order
 */
AttributeRouteInfo.prototype['order'] = undefined;

/**
 * @member {String} name
 */
AttributeRouteInfo.prototype['name'] = undefined;

/**
 * @member {Boolean} suppressLinkGeneration
 */
AttributeRouteInfo.prototype['suppressLinkGeneration'] = undefined;

/**
 * @member {Boolean} suppressPathMatching
 */
AttributeRouteInfo.prototype['suppressPathMatching'] = undefined;






export default AttributeRouteInfo;

