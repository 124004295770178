/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofPermissions from './ProofPermissions';

/**
 * The SaveUserRoleRequest model module.
 * @module model/SaveUserRoleRequest
 * @version V1
 */
class SaveUserRoleRequest {
    /**
     * Constructs a new <code>SaveUserRoleRequest</code>.
     * @alias module:model/SaveUserRoleRequest
     */
    constructor() { 
        
        SaveUserRoleRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SaveUserRoleRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SaveUserRoleRequest} obj Optional instance to populate.
     * @return {module:model/SaveUserRoleRequest} The populated <code>SaveUserRoleRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SaveUserRoleRequest();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('manageTemplates')) {
                obj['manageTemplates'] = ApiClient.convertToType(data['manageTemplates'], 'Boolean');
            }
            if (data.hasOwnProperty('manageWorkflows')) {
                obj['manageWorkflows'] = ApiClient.convertToType(data['manageWorkflows'], 'Boolean');
            }
            if (data.hasOwnProperty('manageBilling')) {
                obj['manageBilling'] = ApiClient.convertToType(data['manageBilling'], 'Boolean');
            }
            if (data.hasOwnProperty('manageAccount')) {
                obj['manageAccount'] = ApiClient.convertToType(data['manageAccount'], 'Boolean');
            }
            if (data.hasOwnProperty('manageUsers')) {
                obj['manageUsers'] = ApiClient.convertToType(data['manageUsers'], 'Boolean');
            }
            if (data.hasOwnProperty('proofPermissions')) {
                obj['proofPermissions'] = ProofPermissions.constructFromObject(data['proofPermissions']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
SaveUserRoleRequest.prototype['name'] = undefined;

/**
 * @member {Boolean} manageTemplates
 */
SaveUserRoleRequest.prototype['manageTemplates'] = undefined;

/**
 * @member {Boolean} manageWorkflows
 */
SaveUserRoleRequest.prototype['manageWorkflows'] = undefined;

/**
 * @member {Boolean} manageBilling
 */
SaveUserRoleRequest.prototype['manageBilling'] = undefined;

/**
 * @member {Boolean} manageAccount
 */
SaveUserRoleRequest.prototype['manageAccount'] = undefined;

/**
 * @member {Boolean} manageUsers
 */
SaveUserRoleRequest.prototype['manageUsers'] = undefined;

/**
 * @member {module:model/ProofPermissions} proofPermissions
 */
SaveUserRoleRequest.prototype['proofPermissions'] = undefined;






export default SaveUserRoleRequest;

