/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import WebHookEventGroupType from './WebHookEventGroupType';
import WebHookEventType from './WebHookEventType';

/**
 * The SaveWebHookRequest model module.
 * @module model/SaveWebHookRequest
 * @version V1
 */
class SaveWebHookRequest {
    /**
     * Constructs a new <code>SaveWebHookRequest</code>.
     * @alias module:model/SaveWebHookRequest
     */
    constructor() { 
        
        SaveWebHookRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SaveWebHookRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SaveWebHookRequest} obj Optional instance to populate.
     * @return {module:model/SaveWebHookRequest} The populated <code>SaveWebHookRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SaveWebHookRequest();

            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('rateLimitPerMin')) {
                obj['rateLimitPerMin'] = ApiClient.convertToType(data['rateLimitPerMin'], 'Number');
            }
            if (data.hasOwnProperty('headers')) {
                obj['headers'] = ApiClient.convertToType(data['headers'], {'String': 'String'});
            }
            if (data.hasOwnProperty('eventTypeGroupsToSend')) {
                obj['eventTypeGroupsToSend'] = ApiClient.convertToType(data['eventTypeGroupsToSend'], [WebHookEventGroupType]);
            }
            if (data.hasOwnProperty('eventTypesToSend')) {
                obj['eventTypesToSend'] = ApiClient.convertToType(data['eventTypesToSend'], [WebHookEventType]);
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} active
 */
SaveWebHookRequest.prototype['active'] = undefined;

/**
 * @member {String} url
 */
SaveWebHookRequest.prototype['url'] = undefined;

/**
 * @member {String} name
 */
SaveWebHookRequest.prototype['name'] = undefined;

/**
 * @member {Number} rateLimitPerMin
 */
SaveWebHookRequest.prototype['rateLimitPerMin'] = undefined;

/**
 * @member {Object.<String, String>} headers
 */
SaveWebHookRequest.prototype['headers'] = undefined;

/**
 * @member {Array.<module:model/WebHookEventGroupType>} eventTypeGroupsToSend
 */
SaveWebHookRequest.prototype['eventTypeGroupsToSend'] = undefined;

/**
 * @member {Array.<module:model/WebHookEventType>} eventTypesToSend
 */
SaveWebHookRequest.prototype['eventTypesToSend'] = undefined;






export default SaveWebHookRequest;

