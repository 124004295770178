/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SendOptions from './SendOptions';

/**
 * The SendMessageToCurrentApproversRequest model module.
 * @module model/SendMessageToCurrentApproversRequest
 * @version V1
 */
class SendMessageToCurrentApproversRequest {
    /**
     * Constructs a new <code>SendMessageToCurrentApproversRequest</code>.
     * @alias module:model/SendMessageToCurrentApproversRequest
     */
    constructor() { 
        
        SendMessageToCurrentApproversRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SendMessageToCurrentApproversRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SendMessageToCurrentApproversRequest} obj Optional instance to populate.
     * @return {module:model/SendMessageToCurrentApproversRequest} The populated <code>SendMessageToCurrentApproversRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SendMessageToCurrentApproversRequest();

            if (data.hasOwnProperty('approverContactIds')) {
                obj['approverContactIds'] = ApiClient.convertToType(data['approverContactIds'], ['Number']);
            }
            if (data.hasOwnProperty('reviewerContactIds')) {
                obj['reviewerContactIds'] = ApiClient.convertToType(data['reviewerContactIds'], ['Number']);
            }
            if (data.hasOwnProperty('sendOptions')) {
                obj['sendOptions'] = SendOptions.constructFromObject(data['sendOptions']);
            }
            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<Number>} approverContactIds
 */
SendMessageToCurrentApproversRequest.prototype['approverContactIds'] = undefined;

/**
 * @member {Array.<Number>} reviewerContactIds
 */
SendMessageToCurrentApproversRequest.prototype['reviewerContactIds'] = undefined;

/**
 * @member {module:model/SendOptions} sendOptions
 */
SendMessageToCurrentApproversRequest.prototype['sendOptions'] = undefined;

/**
 * @member {String} subject
 */
SendMessageToCurrentApproversRequest.prototype['subject'] = undefined;

/**
 * @member {String} message
 */
SendMessageToCurrentApproversRequest.prototype['message'] = undefined;






export default SendMessageToCurrentApproversRequest;

