/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UpdateUserDefaultSettingsRequest model module.
 * @module model/UpdateUserDefaultSettingsRequest
 * @version V1
 */
class UpdateUserDefaultSettingsRequest {
    /**
     * Constructs a new <code>UpdateUserDefaultSettingsRequest</code>.
     * @alias module:model/UpdateUserDefaultSettingsRequest
     */
    constructor() { 
        
        UpdateUserDefaultSettingsRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UpdateUserDefaultSettingsRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UpdateUserDefaultSettingsRequest} obj Optional instance to populate.
     * @return {module:model/UpdateUserDefaultSettingsRequest} The populated <code>UpdateUserDefaultSettingsRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UpdateUserDefaultSettingsRequest();

            if (data.hasOwnProperty('downloadFiles')) {
                obj['downloadFiles'] = ApiClient.convertToType(data['downloadFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('approveIndividualFiles')) {
                obj['approveIndividualFiles'] = ApiClient.convertToType(data['approveIndividualFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('viewAllComments')) {
                obj['viewAllComments'] = ApiClient.convertToType(data['viewAllComments'], 'Boolean');
            }
            if (data.hasOwnProperty('approveWithChanges')) {
                obj['approveWithChanges'] = ApiClient.convertToType(data['approveWithChanges'], 'Boolean');
            }
            if (data.hasOwnProperty('notifyFilesSent')) {
                obj['notifyFilesSent'] = ApiClient.convertToType(data['notifyFilesSent'], 'Boolean');
            }
            if (data.hasOwnProperty('limitVersionVisibility')) {
                obj['limitVersionVisibility'] = ApiClient.convertToType(data['limitVersionVisibility'], 'Boolean');
            }
            if (data.hasOwnProperty('blockCommentingAfterProofDecision')) {
                obj['blockCommentingAfterProofDecision'] = ApiClient.convertToType(data['blockCommentingAfterProofDecision'], 'Boolean');
            }
            if (data.hasOwnProperty('notifyFilesApproved')) {
                obj['notifyFilesApproved'] = ApiClient.convertToType(data['notifyFilesApproved'], 'Boolean');
            }
            if (data.hasOwnProperty('notifyNewComments')) {
                obj['notifyNewComments'] = ApiClient.convertToType(data['notifyNewComments'], 'Boolean');
            }
            if (data.hasOwnProperty('reminderDays')) {
                obj['reminderDays'] = ApiClient.convertToType(data['reminderDays'], 'String');
            }
            if (data.hasOwnProperty('reminderTimeOfDay')) {
                obj['reminderTimeOfDay'] = ApiClient.convertToType(data['reminderTimeOfDay'], 'String');
            }
            if (data.hasOwnProperty('reminderTimeZone')) {
                obj['reminderTimeZone'] = ApiClient.convertToType(data['reminderTimeZone'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} downloadFiles
 */
UpdateUserDefaultSettingsRequest.prototype['downloadFiles'] = undefined;

/**
 * @member {Boolean} approveIndividualFiles
 */
UpdateUserDefaultSettingsRequest.prototype['approveIndividualFiles'] = undefined;

/**
 * @member {Boolean} viewAllComments
 */
UpdateUserDefaultSettingsRequest.prototype['viewAllComments'] = undefined;

/**
 * @member {Boolean} approveWithChanges
 */
UpdateUserDefaultSettingsRequest.prototype['approveWithChanges'] = undefined;

/**
 * @member {Boolean} notifyFilesSent
 */
UpdateUserDefaultSettingsRequest.prototype['notifyFilesSent'] = undefined;

/**
 * @member {Boolean} limitVersionVisibility
 */
UpdateUserDefaultSettingsRequest.prototype['limitVersionVisibility'] = undefined;

/**
 * @member {Boolean} blockCommentingAfterProofDecision
 */
UpdateUserDefaultSettingsRequest.prototype['blockCommentingAfterProofDecision'] = undefined;

/**
 * @member {Boolean} notifyFilesApproved
 */
UpdateUserDefaultSettingsRequest.prototype['notifyFilesApproved'] = undefined;

/**
 * @member {Boolean} notifyNewComments
 */
UpdateUserDefaultSettingsRequest.prototype['notifyNewComments'] = undefined;

/**
 * @member {String} reminderDays
 */
UpdateUserDefaultSettingsRequest.prototype['reminderDays'] = undefined;

/**
 * @member {String} reminderTimeOfDay
 */
UpdateUserDefaultSettingsRequest.prototype['reminderTimeOfDay'] = undefined;

/**
 * @member {String} reminderTimeZone
 */
UpdateUserDefaultSettingsRequest.prototype['reminderTimeZone'] = undefined;






export default UpdateUserDefaultSettingsRequest;

