/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Approver from './Approver';
import ProofRequirement from './ProofRequirement';
import ProofVersionStatus from './ProofVersionStatus';

/**
 * The WorkflowStage model module.
 * @module model/WorkflowStage
 * @version V1
 */
class WorkflowStage {
    /**
     * Constructs a new <code>WorkflowStage</code>.
     * @alias module:model/WorkflowStage
     */
    constructor() { 
        
        WorkflowStage.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>WorkflowStage</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/WorkflowStage} obj Optional instance to populate.
     * @return {module:model/WorkflowStage} The populated <code>WorkflowStage</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WorkflowStage();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('stageOrder')) {
                obj['stageOrder'] = ApiClient.convertToType(data['stageOrder'], 'Number');
            }
            if (data.hasOwnProperty('senderUserId')) {
                obj['senderUserId'] = ApiClient.convertToType(data['senderUserId'], 'Number');
            }
            if (data.hasOwnProperty('templateId')) {
                obj['templateId'] = ApiClient.convertToType(data['templateId'], 'Number');
            }
            if (data.hasOwnProperty('workflowId')) {
                obj['workflowId'] = ApiClient.convertToType(data['workflowId'], 'Number');
            }
            if (data.hasOwnProperty('subjectLine')) {
                obj['subjectLine'] = ApiClient.convertToType(data['subjectLine'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('checklistId')) {
                obj['checklistId'] = ApiClient.convertToType(data['checklistId'], 'Number');
            }
            if (data.hasOwnProperty('isCurrentStage')) {
                obj['isCurrentStage'] = ApiClient.convertToType(data['isCurrentStage'], 'Boolean');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('bccUserIds')) {
                obj['bccUserIds'] = ApiClient.convertToType(data['bccUserIds'], ['Number']);
            }
            if (data.hasOwnProperty('approvers')) {
                obj['approvers'] = ApiClient.convertToType(data['approvers'], [Approver]);
            }
            if (data.hasOwnProperty('reviewers')) {
                obj['reviewers'] = ApiClient.convertToType(data['reviewers'], [Approver]);
            }
            if (data.hasOwnProperty('stageStatus')) {
                obj['stageStatus'] = ApiClient.convertToType(data['stageStatus'], 'String');
            }
            if (data.hasOwnProperty('statusType')) {
                obj['statusType'] = ProofVersionStatus.constructFromObject(data['statusType']);
            }
            if (data.hasOwnProperty('proofRequirements')) {
                obj['proofRequirements'] = ApiClient.convertToType(data['proofRequirements'], [ProofRequirement]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
WorkflowStage.prototype['id'] = undefined;

/**
 * @member {String} name
 */
WorkflowStage.prototype['name'] = undefined;

/**
 * @member {Number} stageOrder
 */
WorkflowStage.prototype['stageOrder'] = undefined;

/**
 * @member {Number} senderUserId
 */
WorkflowStage.prototype['senderUserId'] = undefined;

/**
 * @member {Number} templateId
 */
WorkflowStage.prototype['templateId'] = undefined;

/**
 * @member {Number} workflowId
 */
WorkflowStage.prototype['workflowId'] = undefined;

/**
 * @member {String} subjectLine
 */
WorkflowStage.prototype['subjectLine'] = undefined;

/**
 * @member {String} message
 */
WorkflowStage.prototype['message'] = undefined;

/**
 * @member {Number} checklistId
 */
WorkflowStage.prototype['checklistId'] = undefined;

/**
 * @member {Boolean} isCurrentStage
 */
WorkflowStage.prototype['isCurrentStage'] = undefined;

/**
 * @member {Date} createdAt
 */
WorkflowStage.prototype['createdAt'] = undefined;

/**
 * @member {Array.<Number>} bccUserIds
 */
WorkflowStage.prototype['bccUserIds'] = undefined;

/**
 * @member {Array.<module:model/Approver>} approvers
 */
WorkflowStage.prototype['approvers'] = undefined;

/**
 * @member {Array.<module:model/Approver>} reviewers
 */
WorkflowStage.prototype['reviewers'] = undefined;

/**
 * @member {String} stageStatus
 */
WorkflowStage.prototype['stageStatus'] = undefined;

/**
 * @member {module:model/ProofVersionStatus} statusType
 */
WorkflowStage.prototype['statusType'] = undefined;

/**
 * @member {Array.<module:model/ProofRequirement>} proofRequirements
 */
WorkflowStage.prototype['proofRequirements'] = undefined;






export default WorkflowStage;

