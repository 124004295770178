/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Stream model module.
 * @module model/Stream
 * @version V1
 */
class Stream {
    /**
     * Constructs a new <code>Stream</code>.
     * @alias module:model/Stream
     */
    constructor() { 
        
        Stream.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Stream</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Stream} obj Optional instance to populate.
     * @return {module:model/Stream} The populated <code>Stream</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Stream();

            if (data.hasOwnProperty('canRead')) {
                obj['canRead'] = ApiClient.convertToType(data['canRead'], 'Boolean');
            }
            if (data.hasOwnProperty('canSeek')) {
                obj['canSeek'] = ApiClient.convertToType(data['canSeek'], 'Boolean');
            }
            if (data.hasOwnProperty('canTimeout')) {
                obj['canTimeout'] = ApiClient.convertToType(data['canTimeout'], 'Boolean');
            }
            if (data.hasOwnProperty('canWrite')) {
                obj['canWrite'] = ApiClient.convertToType(data['canWrite'], 'Boolean');
            }
            if (data.hasOwnProperty('length')) {
                obj['length'] = ApiClient.convertToType(data['length'], 'Number');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('readTimeout')) {
                obj['readTimeout'] = ApiClient.convertToType(data['readTimeout'], 'Number');
            }
            if (data.hasOwnProperty('writeTimeout')) {
                obj['writeTimeout'] = ApiClient.convertToType(data['writeTimeout'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} canRead
 */
Stream.prototype['canRead'] = undefined;

/**
 * @member {Boolean} canSeek
 */
Stream.prototype['canSeek'] = undefined;

/**
 * @member {Boolean} canTimeout
 */
Stream.prototype['canTimeout'] = undefined;

/**
 * @member {Boolean} canWrite
 */
Stream.prototype['canWrite'] = undefined;

/**
 * @member {Number} length
 */
Stream.prototype['length'] = undefined;

/**
 * @member {Number} position
 */
Stream.prototype['position'] = undefined;

/**
 * @member {Number} readTimeout
 */
Stream.prototype['readTimeout'] = undefined;

/**
 * @member {Number} writeTimeout
 */
Stream.prototype['writeTimeout'] = undefined;






export default Stream;

