/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProofPermissions from './ProofPermissions';
import UserRoleMembership from './UserRoleMembership';

/**
 * The UserPermissions model module.
 * @module model/UserPermissions
 * @version V1
 */
class UserPermissions {
    /**
     * Constructs a new <code>UserPermissions</code>.
     * @alias module:model/UserPermissions
     */
    constructor() { 
        
        UserPermissions.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserPermissions</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserPermissions} obj Optional instance to populate.
     * @return {module:model/UserPermissions} The populated <code>UserPermissions</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserPermissions();

            if (data.hasOwnProperty('roleId')) {
                obj['roleId'] = ApiClient.convertToType(data['roleId'], 'Number');
            }
            if (data.hasOwnProperty('roleName')) {
                obj['roleName'] = ApiClient.convertToType(data['roleName'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('sendProofs')) {
                obj['sendProofs'] = ApiClient.convertToType(data['sendProofs'], 'Boolean');
            }
            if (data.hasOwnProperty('manageTemplates')) {
                obj['manageTemplates'] = ApiClient.convertToType(data['manageTemplates'], 'Boolean');
            }
            if (data.hasOwnProperty('manageWorkflows')) {
                obj['manageWorkflows'] = ApiClient.convertToType(data['manageWorkflows'], 'Boolean');
            }
            if (data.hasOwnProperty('manageAccount')) {
                obj['manageAccount'] = ApiClient.convertToType(data['manageAccount'], 'Boolean');
            }
            if (data.hasOwnProperty('manageBilling')) {
                obj['manageBilling'] = ApiClient.convertToType(data['manageBilling'], 'Boolean');
            }
            if (data.hasOwnProperty('manageUsers')) {
                obj['manageUsers'] = ApiClient.convertToType(data['manageUsers'], 'Boolean');
            }
            if (data.hasOwnProperty('createProofs')) {
                obj['createProofs'] = ApiClient.convertToType(data['createProofs'], 'Boolean');
            }
            if (data.hasOwnProperty('proofAccess')) {
                obj['proofAccess'] = ProofPermissions.constructFromObject(data['proofAccess']);
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = UserRoleMembership.constructFromObject(data['role']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} roleId
 */
UserPermissions.prototype['roleId'] = undefined;

/**
 * @member {String} roleName
 */
UserPermissions.prototype['roleName'] = undefined;

/**
 * @member {Number} userId
 */
UserPermissions.prototype['userId'] = undefined;

/**
 * @member {Boolean} sendProofs
 */
UserPermissions.prototype['sendProofs'] = undefined;

/**
 * @member {Boolean} manageTemplates
 */
UserPermissions.prototype['manageTemplates'] = undefined;

/**
 * @member {Boolean} manageWorkflows
 */
UserPermissions.prototype['manageWorkflows'] = undefined;

/**
 * @member {Boolean} manageAccount
 */
UserPermissions.prototype['manageAccount'] = undefined;

/**
 * @member {Boolean} manageBilling
 */
UserPermissions.prototype['manageBilling'] = undefined;

/**
 * @member {Boolean} manageUsers
 */
UserPermissions.prototype['manageUsers'] = undefined;

/**
 * @member {Boolean} createProofs
 */
UserPermissions.prototype['createProofs'] = undefined;

/**
 * @member {module:model/ProofPermissions} proofAccess
 */
UserPermissions.prototype['proofAccess'] = undefined;

/**
 * @member {module:model/UserRoleMembership} role
 */
UserPermissions.prototype['role'] = undefined;






export default UserPermissions;

