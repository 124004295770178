/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TemplateValidationStatus model module.
 * @module model/TemplateValidationStatus
 * @version V1
 */
class TemplateValidationStatus {
    /**
     * Constructs a new <code>TemplateValidationStatus</code>.
     * @alias module:model/TemplateValidationStatus
     */
    constructor() { 
        
        TemplateValidationStatus.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TemplateValidationStatus</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TemplateValidationStatus} obj Optional instance to populate.
     * @return {module:model/TemplateValidationStatus} The populated <code>TemplateValidationStatus</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TemplateValidationStatus();

            if (data.hasOwnProperty('valid')) {
                obj['valid'] = ApiClient.convertToType(data['valid'], 'Boolean');
            }
            if (data.hasOwnProperty('error')) {
                obj['error'] = ApiClient.convertToType(data['error'], 'String');
            }
            if (data.hasOwnProperty('testHtmlOutput')) {
                obj['testHtmlOutput'] = ApiClient.convertToType(data['testHtmlOutput'], 'String');
            }
            if (data.hasOwnProperty('testSubjectOutput')) {
                obj['testSubjectOutput'] = ApiClient.convertToType(data['testSubjectOutput'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} valid
 */
TemplateValidationStatus.prototype['valid'] = undefined;

/**
 * @member {String} error
 */
TemplateValidationStatus.prototype['error'] = undefined;

/**
 * @member {String} testHtmlOutput
 */
TemplateValidationStatus.prototype['testHtmlOutput'] = undefined;

/**
 * @member {String} testSubjectOutput
 */
TemplateValidationStatus.prototype['testSubjectOutput'] = undefined;






export default TemplateValidationStatus;

