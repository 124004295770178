/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CreateAnnotationCommentRequest model module.
 * @module model/CreateAnnotationCommentRequest
 * @version V1
 */
class CreateAnnotationCommentRequest {
    /**
     * Constructs a new <code>CreateAnnotationCommentRequest</code>.
     * @alias module:model/CreateAnnotationCommentRequest
     */
    constructor() { 
        
        CreateAnnotationCommentRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CreateAnnotationCommentRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreateAnnotationCommentRequest} obj Optional instance to populate.
     * @return {module:model/CreateAnnotationCommentRequest} The populated <code>CreateAnnotationCommentRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateAnnotationCommentRequest();

            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('proofAnnotationId')) {
                obj['proofAnnotationId'] = ApiClient.convertToType(data['proofAnnotationId'], 'Number');
            }
            if (data.hasOwnProperty('ownerName')) {
                obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
            }
            if (data.hasOwnProperty('ownerInitials')) {
                obj['ownerInitials'] = ApiClient.convertToType(data['ownerInitials'], 'String');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofFileId')) {
                obj['proofFileId'] = ApiClient.convertToType(data['proofFileId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} userId
 */
CreateAnnotationCommentRequest.prototype['userId'] = undefined;

/**
 * @member {Number} approverId
 */
CreateAnnotationCommentRequest.prototype['approverId'] = undefined;

/**
 * @member {Number} accountId
 */
CreateAnnotationCommentRequest.prototype['accountId'] = undefined;

/**
 * @member {Number} proofAnnotationId
 */
CreateAnnotationCommentRequest.prototype['proofAnnotationId'] = undefined;

/**
 * @member {String} ownerName
 */
CreateAnnotationCommentRequest.prototype['ownerName'] = undefined;

/**
 * @member {String} ownerInitials
 */
CreateAnnotationCommentRequest.prototype['ownerInitials'] = undefined;

/**
 * @member {Number} proofId
 */
CreateAnnotationCommentRequest.prototype['proofId'] = undefined;

/**
 * @member {Number} proofFileId
 */
CreateAnnotationCommentRequest.prototype['proofFileId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
CreateAnnotationCommentRequest.prototype['proofVersionId'] = undefined;

/**
 * @member {String} text
 */
CreateAnnotationCommentRequest.prototype['text'] = undefined;






export default CreateAnnotationCommentRequest;

