/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import EmailAuthorization from '../model/EmailAuthorization';
import EmailConfiguration from '../model/EmailConfiguration';
import EmailLog from '../model/EmailLog';
import PreviewReminderEmailRequest from '../model/PreviewReminderEmailRequest';
import SmtpServerInfo from '../model/SmtpServerInfo';
import SuccessResponse from '../model/SuccessResponse';

/**
* Email service.
* @module api/EmailApi
* @version V1
*/
export default class EmailApi {

    /**
    * Constructs a new EmailApi. 
    * @alias module:api/EmailApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the authorizeDomain operation.
     * @callback module:api/EmailApi~authorizeDomainCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EmailAuthorization} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} domain 
     * @param {module:api/EmailApi~authorizeDomainCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/EmailAuthorization}
     */
    authorizeDomain(domain, callback) {
      let postBody = null;
      // verify the required parameter 'domain' is set
      if (domain === undefined || domain === null) {
        throw new Error("Missing the required parameter 'domain' when calling authorizeDomain");
      }

      let pathParams = {
        'domain': domain
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EmailAuthorization;
      return this.apiClient.callApi(
        '/email/domain/authorization/{domain}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteDomainAuthorization operation.
     * @callback module:api/EmailApi~deleteDomainAuthorizationCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} sigId 
     * @param {module:api/EmailApi~deleteDomainAuthorizationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    deleteDomainAuthorization(sigId, callback) {
      let postBody = null;
      // verify the required parameter 'sigId' is set
      if (sigId === undefined || sigId === null) {
        throw new Error("Missing the required parameter 'sigId' when calling deleteDomainAuthorization");
      }

      let pathParams = {
        'sigId': sigId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/email/domain/authorization/{sigId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteSmtpServer operation.
     * @callback module:api/EmailApi~deleteSmtpServerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.accountEmail  (default to false)
     * @param {module:api/EmailApi~deleteSmtpServerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteSmtpServer(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'accountEmail': opts['accountEmail']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/email/smtp', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAuthStatus operation.
     * @callback module:api/EmailApi~getAuthStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EmailConfiguration} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/EmailApi~getAuthStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/EmailConfiguration}
     */
    getAuthStatus(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EmailConfiguration;
      return this.apiClient.callApi(
        '/email/authorization-status', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getDomainAuthorizations operation.
     * @callback module:api/EmailApi~getDomainAuthorizationsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/EmailAuthorization>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/EmailApi~getDomainAuthorizationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/EmailAuthorization>}
     */
    getDomainAuthorizations(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [EmailAuthorization];
      return this.apiClient.callApi(
        '/email/domain/authorization/status', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getImage operation.
     * @callback module:api/EmailApi~getImageCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} s3Key 
     * @param {module:api/EmailApi~getImageCallback} callback The callback function, accepting three arguments: error, data, response
     */
    getImage(s3Key, callback) {
      let postBody = null;
      // verify the required parameter 's3Key' is set
      if (s3Key === undefined || s3Key === null) {
        throw new Error("Missing the required parameter 's3Key' when calling getImage");
      }

      let pathParams = {
        's3Key': s3Key
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/cdn/{s3Key}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getSmtpServer operation.
     * @callback module:api/EmailApi~getSmtpServerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.accountEmail  (default to false)
     * @param {module:api/EmailApi~getSmtpServerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    getSmtpServer(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'accountEmail': opts['accountEmail']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/email/smtp', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the previewEmail operation.
     * @callback module:api/EmailApi~previewEmailCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/PreviewReminderEmailRequest} opts.previewReminderEmailRequest 
     * @param {module:api/EmailApi~previewEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    previewEmail(opts, callback) {
      opts = opts || {};
      let postBody = opts['previewReminderEmailRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/email/preview', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the registerSender operation.
     * @callback module:api/EmailApi~registerSenderCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/EmailApi~registerSenderCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    registerSender(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/email/authorize-sender', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the registerSmtpServer operation.
     * @callback module:api/EmailApi~registerSmtpServerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.accountEmail  (default to false)
     * @param {module:model/SmtpServerInfo} opts.smtpServerInfo 
     * @param {module:api/EmailApi~registerSmtpServerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    registerSmtpServer(opts, callback) {
      opts = opts || {};
      let postBody = opts['smtpServerInfo'];

      let pathParams = {
      };
      let queryParams = {
        'accountEmail': opts['accountEmail']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/email/smtp', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the takeInStatusUpdates operation.
     * @callback module:api/EmailApi~takeInStatusUpdatesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/EmailApi~takeInStatusUpdatesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    takeInStatusUpdates(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/email/status-updates', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the takeInStatusUpdatesSmtp operation.
     * @callback module:api/EmailApi~takeInStatusUpdatesSmtpCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} sendId 
     * @param {module:api/EmailApi~takeInStatusUpdatesSmtpCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    takeInStatusUpdatesSmtp(sendId, callback) {
      let postBody = null;
      // verify the required parameter 'sendId' is set
      if (sendId === undefined || sendId === null) {
        throw new Error("Missing the required parameter 'sendId' when calling takeInStatusUpdatesSmtp");
      }

      let pathParams = {
        'sendId': sendId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/email/status-updates/smtp/{sendId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the unAuthorizeSender operation.
     * @callback module:api/EmailApi~unAuthorizeSenderCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/EmailApi~unAuthorizeSenderCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    unAuthorizeSender(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/email/unauthorize-sender', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the validateSendMethod operation.
     * @callback module:api/EmailApi~validateSendMethodCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} validationHash 
     * @param {module:api/EmailApi~validateSendMethodCallback} callback The callback function, accepting three arguments: error, data, response
     */
    validateSendMethod(validationHash, callback) {
      let postBody = null;
      // verify the required parameter 'validationHash' is set
      if (validationHash === undefined || validationHash === null) {
        throw new Error("Missing the required parameter 'validationHash' when calling validateSendMethod");
      }

      let pathParams = {
        'validationHash': validationHash
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/email/validate/{validationHash}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the validateSendMethod_0 operation.
     * @callback module:api/EmailApi~validateSendMethod_0Callback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} validationHash 
     * @param {module:api/EmailApi~validateSendMethod_0Callback} callback The callback function, accepting three arguments: error, data, response
     */
    validateSendMethod_0(validationHash, callback) {
      let postBody = null;
      // verify the required parameter 'validationHash' is set
      if (validationHash === undefined || validationHash === null) {
        throw new Error("Missing the required parameter 'validationHash' when calling validateSendMethod_0");
      }

      let pathParams = {
        'validationHash': validationHash
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/email/validate/{validationHash}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the viewEmail operation.
     * @callback module:api/EmailApi~viewEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EmailLog} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} emailLogId 
     * @param {module:api/EmailApi~viewEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/EmailLog}
     */
    viewEmail(emailLogId, callback) {
      let postBody = null;
      // verify the required parameter 'emailLogId' is set
      if (emailLogId === undefined || emailLogId === null) {
        throw new Error("Missing the required parameter 'emailLogId' when calling viewEmail");
      }

      let pathParams = {
        'emailLogId': emailLogId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EmailLog;
      return this.apiClient.callApi(
        '/email/{emailLogId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
