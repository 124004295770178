/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SendOptions from './SendOptions';

/**
 * The CreateNewProofVersionRequest model module.
 * @module model/CreateNewProofVersionRequest
 * @version V1
 */
class CreateNewProofVersionRequest {
    /**
     * Constructs a new <code>CreateNewProofVersionRequest</code>.
     * @alias module:model/CreateNewProofVersionRequest
     */
    constructor() { 
        
        CreateNewProofVersionRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CreateNewProofVersionRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreateNewProofVersionRequest} obj Optional instance to populate.
     * @return {module:model/CreateNewProofVersionRequest} The populated <code>CreateNewProofVersionRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateNewProofVersionRequest();

            if (data.hasOwnProperty('senderUserId')) {
                obj['senderUserId'] = ApiClient.convertToType(data['senderUserId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionFileIdsToPromoteToTheNextVersion')) {
                obj['proofVersionFileIdsToPromoteToTheNextVersion'] = ApiClient.convertToType(data['proofVersionFileIdsToPromoteToTheNextVersion'], ['Number']);
            }
            if (data.hasOwnProperty('proofName')) {
                obj['proofName'] = ApiClient.convertToType(data['proofName'], 'String');
            }
            if (data.hasOwnProperty('subjectLine')) {
                obj['subjectLine'] = ApiClient.convertToType(data['subjectLine'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('templateId')) {
                obj['templateId'] = ApiClient.convertToType(data['templateId'], 'Number');
            }
            if (data.hasOwnProperty('sendToWorkflowStageId')) {
                obj['sendToWorkflowStageId'] = ApiClient.convertToType(data['sendToWorkflowStageId'], 'Number');
            }
            if (data.hasOwnProperty('sendOptions')) {
                obj['sendOptions'] = SendOptions.constructFromObject(data['sendOptions']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} senderUserId
 */
CreateNewProofVersionRequest.prototype['senderUserId'] = undefined;

/**
 * @member {Number} proofId
 */
CreateNewProofVersionRequest.prototype['proofId'] = undefined;

/**
 * @member {Array.<Number>} proofVersionFileIdsToPromoteToTheNextVersion
 */
CreateNewProofVersionRequest.prototype['proofVersionFileIdsToPromoteToTheNextVersion'] = undefined;

/**
 * @member {String} proofName
 */
CreateNewProofVersionRequest.prototype['proofName'] = undefined;

/**
 * @member {String} subjectLine
 */
CreateNewProofVersionRequest.prototype['subjectLine'] = undefined;

/**
 * @member {String} message
 */
CreateNewProofVersionRequest.prototype['message'] = undefined;

/**
 * @member {Number} templateId
 */
CreateNewProofVersionRequest.prototype['templateId'] = undefined;

/**
 * @member {Number} sendToWorkflowStageId
 */
CreateNewProofVersionRequest.prototype['sendToWorkflowStageId'] = undefined;

/**
 * @member {module:model/SendOptions} sendOptions
 */
CreateNewProofVersionRequest.prototype['sendOptions'] = undefined;






export default CreateNewProofVersionRequest;

