/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CancellationToken from './CancellationToken';
import ClaimsPrincipal from './ClaimsPrincipal';
import ConnectionInfo from './ConnectionInfo';
import HttpRequest from './HttpRequest';
import HttpResponse from './HttpResponse';
import ISession from './ISession';
import TypeObjectKeyValuePair from './TypeObjectKeyValuePair';
import WebSocketManager from './WebSocketManager';

/**
 * The HttpContext model module.
 * @module model/HttpContext
 * @version V1
 */
class HttpContext {
    /**
     * Constructs a new <code>HttpContext</code>.
     * @alias module:model/HttpContext
     */
    constructor() { 
        
        HttpContext.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>HttpContext</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/HttpContext} obj Optional instance to populate.
     * @return {module:model/HttpContext} The populated <code>HttpContext</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new HttpContext();

            if (data.hasOwnProperty('features')) {
                obj['features'] = ApiClient.convertToType(data['features'], [TypeObjectKeyValuePair]);
            }
            if (data.hasOwnProperty('request')) {
                obj['request'] = HttpRequest.constructFromObject(data['request']);
            }
            if (data.hasOwnProperty('response')) {
                obj['response'] = HttpResponse.constructFromObject(data['response']);
            }
            if (data.hasOwnProperty('connection')) {
                obj['connection'] = ConnectionInfo.constructFromObject(data['connection']);
            }
            if (data.hasOwnProperty('webSockets')) {
                obj['webSockets'] = WebSocketManager.constructFromObject(data['webSockets']);
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = ClaimsPrincipal.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], {'String': Object});
            }
            if (data.hasOwnProperty('requestServices')) {
                obj['requestServices'] = ApiClient.convertToType(data['requestServices'], Object);
            }
            if (data.hasOwnProperty('requestAborted')) {
                obj['requestAborted'] = CancellationToken.constructFromObject(data['requestAborted']);
            }
            if (data.hasOwnProperty('traceIdentifier')) {
                obj['traceIdentifier'] = ApiClient.convertToType(data['traceIdentifier'], 'String');
            }
            if (data.hasOwnProperty('session')) {
                obj['session'] = ISession.constructFromObject(data['session']);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/TypeObjectKeyValuePair>} features
 */
HttpContext.prototype['features'] = undefined;

/**
 * @member {module:model/HttpRequest} request
 */
HttpContext.prototype['request'] = undefined;

/**
 * @member {module:model/HttpResponse} response
 */
HttpContext.prototype['response'] = undefined;

/**
 * @member {module:model/ConnectionInfo} connection
 */
HttpContext.prototype['connection'] = undefined;

/**
 * @member {module:model/WebSocketManager} webSockets
 */
HttpContext.prototype['webSockets'] = undefined;

/**
 * @member {module:model/ClaimsPrincipal} user
 */
HttpContext.prototype['user'] = undefined;

/**
 * @member {Object.<String, Object>} items
 */
HttpContext.prototype['items'] = undefined;

/**
 * @member {Object} requestServices
 */
HttpContext.prototype['requestServices'] = undefined;

/**
 * @member {module:model/CancellationToken} requestAborted
 */
HttpContext.prototype['requestAborted'] = undefined;

/**
 * @member {String} traceIdentifier
 */
HttpContext.prototype['traceIdentifier'] = undefined;

/**
 * @member {module:model/ISession} session
 */
HttpContext.prototype['session'] = undefined;






export default HttpContext;

