/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomAttributeData from './CustomAttributeData';
import MethodInfo from './MethodInfo';
import Module from './Module';
import SecurityRuleSet from './SecurityRuleSet';

/**
 * The Assembly model module.
 * @module model/Assembly
 * @version V1
 */
class Assembly {
    /**
     * Constructs a new <code>Assembly</code>.
     * @alias module:model/Assembly
     */
    constructor() { 
        
        Assembly.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Assembly</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Assembly} obj Optional instance to populate.
     * @return {module:model/Assembly} The populated <code>Assembly</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Assembly();

            if (data.hasOwnProperty('definedTypes')) {
                obj['definedTypes'] = ApiClient.convertToType(data['definedTypes'], ['String']);
            }
            if (data.hasOwnProperty('exportedTypes')) {
                obj['exportedTypes'] = ApiClient.convertToType(data['exportedTypes'], ['String']);
            }
            if (data.hasOwnProperty('codeBase')) {
                obj['codeBase'] = ApiClient.convertToType(data['codeBase'], 'String');
            }
            if (data.hasOwnProperty('entryPoint')) {
                obj['entryPoint'] = MethodInfo.constructFromObject(data['entryPoint']);
            }
            if (data.hasOwnProperty('fullName')) {
                obj['fullName'] = ApiClient.convertToType(data['fullName'], 'String');
            }
            if (data.hasOwnProperty('imageRuntimeVersion')) {
                obj['imageRuntimeVersion'] = ApiClient.convertToType(data['imageRuntimeVersion'], 'String');
            }
            if (data.hasOwnProperty('isDynamic')) {
                obj['isDynamic'] = ApiClient.convertToType(data['isDynamic'], 'Boolean');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = ApiClient.convertToType(data['location'], 'String');
            }
            if (data.hasOwnProperty('reflectionOnly')) {
                obj['reflectionOnly'] = ApiClient.convertToType(data['reflectionOnly'], 'Boolean');
            }
            if (data.hasOwnProperty('isCollectible')) {
                obj['isCollectible'] = ApiClient.convertToType(data['isCollectible'], 'Boolean');
            }
            if (data.hasOwnProperty('isFullyTrusted')) {
                obj['isFullyTrusted'] = ApiClient.convertToType(data['isFullyTrusted'], 'Boolean');
            }
            if (data.hasOwnProperty('customAttributes')) {
                obj['customAttributes'] = ApiClient.convertToType(data['customAttributes'], [CustomAttributeData]);
            }
            if (data.hasOwnProperty('escapedCodeBase')) {
                obj['escapedCodeBase'] = ApiClient.convertToType(data['escapedCodeBase'], 'String');
            }
            if (data.hasOwnProperty('manifestModule')) {
                obj['manifestModule'] = Module.constructFromObject(data['manifestModule']);
            }
            if (data.hasOwnProperty('modules')) {
                obj['modules'] = ApiClient.convertToType(data['modules'], [Module]);
            }
            if (data.hasOwnProperty('globalAssemblyCache')) {
                obj['globalAssemblyCache'] = ApiClient.convertToType(data['globalAssemblyCache'], 'Boolean');
            }
            if (data.hasOwnProperty('hostContext')) {
                obj['hostContext'] = ApiClient.convertToType(data['hostContext'], 'Number');
            }
            if (data.hasOwnProperty('securityRuleSet')) {
                obj['securityRuleSet'] = SecurityRuleSet.constructFromObject(data['securityRuleSet']);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<String>} definedTypes
 */
Assembly.prototype['definedTypes'] = undefined;

/**
 * @member {Array.<String>} exportedTypes
 */
Assembly.prototype['exportedTypes'] = undefined;

/**
 * @member {String} codeBase
 */
Assembly.prototype['codeBase'] = undefined;

/**
 * @member {module:model/MethodInfo} entryPoint
 */
Assembly.prototype['entryPoint'] = undefined;

/**
 * @member {String} fullName
 */
Assembly.prototype['fullName'] = undefined;

/**
 * @member {String} imageRuntimeVersion
 */
Assembly.prototype['imageRuntimeVersion'] = undefined;

/**
 * @member {Boolean} isDynamic
 */
Assembly.prototype['isDynamic'] = undefined;

/**
 * @member {String} location
 */
Assembly.prototype['location'] = undefined;

/**
 * @member {Boolean} reflectionOnly
 */
Assembly.prototype['reflectionOnly'] = undefined;

/**
 * @member {Boolean} isCollectible
 */
Assembly.prototype['isCollectible'] = undefined;

/**
 * @member {Boolean} isFullyTrusted
 */
Assembly.prototype['isFullyTrusted'] = undefined;

/**
 * @member {Array.<module:model/CustomAttributeData>} customAttributes
 */
Assembly.prototype['customAttributes'] = undefined;

/**
 * @member {String} escapedCodeBase
 */
Assembly.prototype['escapedCodeBase'] = undefined;

/**
 * @member {module:model/Module} manifestModule
 */
Assembly.prototype['manifestModule'] = undefined;

/**
 * @member {Array.<module:model/Module>} modules
 */
Assembly.prototype['modules'] = undefined;

/**
 * @member {Boolean} globalAssemblyCache
 */
Assembly.prototype['globalAssemblyCache'] = undefined;

/**
 * @member {Number} hostContext
 */
Assembly.prototype['hostContext'] = undefined;

/**
 * @member {module:model/SecurityRuleSet} securityRuleSet
 */
Assembly.prototype['securityRuleSet'] = undefined;






export default Assembly;

