/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AppEventType from './AppEventType';

/**
 * The CreateEventRequest model module.
 * @module model/CreateEventRequest
 * @version V1
 */
class CreateEventRequest {
    /**
     * Constructs a new <code>CreateEventRequest</code>.
     * @alias module:model/CreateEventRequest
     */
    constructor() { 
        
        CreateEventRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CreateEventRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreateEventRequest} obj Optional instance to populate.
     * @return {module:model/CreateEventRequest} The populated <code>CreateEventRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateEventRequest();

            if (data.hasOwnProperty('appEventType')) {
                obj['appEventType'] = AppEventType.constructFromObject(data['appEventType']);
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionFileId')) {
                obj['proofVersionFileId'] = ApiClient.convertToType(data['proofVersionFileId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/AppEventType} appEventType
 */
CreateEventRequest.prototype['appEventType'] = undefined;

/**
 * @member {String} description
 */
CreateEventRequest.prototype['description'] = undefined;

/**
 * @member {Number} proofVersionId
 */
CreateEventRequest.prototype['proofVersionId'] = undefined;

/**
 * @member {Number} proofVersionFileId
 */
CreateEventRequest.prototype['proofVersionFileId'] = undefined;






export default CreateEventRequest;

