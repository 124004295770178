/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AshoreTeamShortInfo from './AshoreTeamShortInfo';

/**
 * The UpdateUserRequest model module.
 * @module model/UpdateUserRequest
 * @version V1
 */
class UpdateUserRequest {
    /**
     * Constructs a new <code>UpdateUserRequest</code>.
     * @alias module:model/UpdateUserRequest
     */
    constructor() { 
        
        UpdateUserRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UpdateUserRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UpdateUserRequest} obj Optional instance to populate.
     * @return {module:model/UpdateUserRequest} The populated <code>UpdateUserRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UpdateUserRequest();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('roleId')) {
                obj['roleId'] = ApiClient.convertToType(data['roleId'], 'Number');
            }
            if (data.hasOwnProperty('teams')) {
                obj['teams'] = ApiClient.convertToType(data['teams'], [AshoreTeamShortInfo]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
UpdateUserRequest.prototype['name'] = undefined;

/**
 * @member {String} email
 */
UpdateUserRequest.prototype['email'] = undefined;

/**
 * @member {String} phone
 */
UpdateUserRequest.prototype['phone'] = undefined;

/**
 * @member {String} title
 */
UpdateUserRequest.prototype['title'] = undefined;

/**
 * @member {Number} roleId
 */
UpdateUserRequest.prototype['roleId'] = undefined;

/**
 * @member {Array.<module:model/AshoreTeamShortInfo>} teams
 */
UpdateUserRequest.prototype['teams'] = undefined;






export default UpdateUserRequest;

