import TagApi from "../generated/api/TagApi";
import SaveTagRequest from "../generated/model/SaveTagRequest";
import SaveTagFilterRequest from "../generated/model/SaveTagFilterRequest";

class TagsService {
  constructor() {
    this.TagApi = new TagApi();
  }

  async Save(tag) {
    const saveTagRequest = new SaveTagRequest(tag.name);
    if (tag.id > 0) {
      return window.$A.HandleApiResponse(
        this.TagApi.updateTag(
          tag.id,
          { saveTagRequest: saveTagRequest },
          null
        ),
        "Tag Has Been Saved"
      );
    } else {
      return window.$A.HandleApiResponse(
        this.TagApi.createTag({ saveTagRequest: saveTagRequest }, null),
        "Tag Has Been Saved"
      );
    }
  }

  async SaveTagFilter(tagFilter) {
    const saveTagFilterRequest = SaveTagFilterRequest.constructFromObject(
      tagFilter
    );
    if (tagFilter.id > 0) {
      return window.$A.HandleApiResponse(
        this.TagApi.updateTagFilter(
          tagFilter.id,
          { saveTagFilterRequest: saveTagFilterRequest },
          null
        ),
        "Tag Filter Has Been Saved"
      );
    } else {
      return window.$A.HandleApiResponse(
        this.TagApi.createTagFilter(
          { saveTagFilterRequest: saveTagFilterRequest },
          null
        ),
        "Tag Filter Has Been Saved"
      );
    }
  }
  async Delete(tagId) {
    return window.$A.HandleApiResponse(
      this.TagApi.deleteTag(tagId, null),
      "Tag Has Been Deleted"
    );
  }
  async DeleteTagFilter(tagFilterId) {
    return window.$A.HandleApiResponse(
      this.TagApi.deleteTagFilter(tagFilterId, null),
      "Tag Filter Has Been Deleted"
    );
  }
  async GetAll() {
    return window.$A.HandleApiResponse(this.TagApi.getAll(null), "", true);
  }
  async GetAllTagFilters() {
    return window.$A.HandleApiResponse(
      this.TagApi.getAllTagFilters(null),
      "",
      true
    );
  }
  async GetTag(tagId) {
    return window.$A.HandleApiResponse(
      this.TagApi.getTag(tagId, null),
      "",
      true
    );
  }
  async GetTagFilter(tagFilterId) {
    return window.$A.HandleApiResponse(
      this.TagApi.getTagFilter(tagFilterId, null),
      "",
      true
    );
  }
  async LinkProof(tagId, proofId, preventAlert) {
    if (preventAlert === undefined) {
      preventAlert = false;
    }
    return window.$A.HandleApiResponse(
      this.TagApi.createTagLink(tagId, {
        itemIdToTag: proofId,
        tagType: 1,
      }),
      "Proof Has Been Linked",
      preventAlert
    );
  }
  async UnlinkProof(tagId, tagLinkId) {
    return window.$A.HandleApiResponse(
      this.TagApi.deleteTagLink(tagId, tagLinkId),
      "Proof Has Been Unlinked"
    );
  }
  async UnlinkProofByTaggedItemId(tagId, taggedItemId, tagType, preventAlert) {
    if (preventAlert === undefined) {
      preventAlert = false;
    }
    return window.$A.HandleApiResponse(
      this.TagApi.deleteTagLinkByTaggedItemId(
        tagId,
        taggedItemId,
        tagType
      ),
      "Proof Has Been Unlinked",
      preventAlert
    );
  }
}

export default TagsService;
