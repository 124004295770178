/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import EmailSendData from '../model/EmailSendData';
import ResetPasswordRequest from '../model/ResetPasswordRequest';
import SmtpServerInfo from '../model/SmtpServerInfo';
import SuccessResponse from '../model/SuccessResponse';
import UpdatePasswordRequest from '../model/UpdatePasswordRequest';
import UpdatePasswordWithTokenRequest from '../model/UpdatePasswordWithTokenRequest';
import UpdateUserDefaultSettingsRequest from '../model/UpdateUserDefaultSettingsRequest';
import UpdateUserRequest from '../model/UpdateUserRequest';
import User from '../model/User';
import UserSetting from '../model/UserSetting';

/**
* User service.
* @module api/UserApi
* @version V1
*/
export default class UserApi {

    /**
    * Constructs a new UserApi. 
    * @alias module:api/UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getUser operation.
     * @callback module:api/UserApi~getUserCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/UserApi~getUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    getUser(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUserAllSendMethods operation.
     * @callback module:api/UserApi~getUserAllSendMethodsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/EmailSendData>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/UserApi~getUserAllSendMethodsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/EmailSendData>}
     */
    getUserAllSendMethods(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [EmailSendData];
      return this.apiClient.callApi(
        '/user/send-method', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUserDefaultSettings operation.
     * @callback module:api/UserApi~getUserDefaultSettingsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserSetting} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {module:api/UserApi~getUserDefaultSettingsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserSetting}
     */
    getUserDefaultSettings(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling getUserDefaultSettings");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserSetting;
      return this.apiClient.callApi(
        '/user/{userId}/default-settings', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUserSendMethodStatus operation.
     * @callback module:api/UserApi~getUserSendMethodStatusCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.sendType 
     * @param {module:api/UserApi~getUserSendMethodStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    getUserSendMethodStatus(id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getUserSendMethodStatus");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'sendType': opts['sendType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/user/send-method/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUserSignature operation.
     * @callback module:api/UserApi~getUserSignatureCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/UserApi~getUserSignatureCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getUserSignature(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/user/signature', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the resetPassword operation.
     * @callback module:api/UserApi~resetPasswordCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordRequest} opts.resetPasswordRequest 
     * @param {module:api/UserApi~resetPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    resetPassword(opts, callback) {
      opts = opts || {};
      let postBody = opts['resetPasswordRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/user/password/reset', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the resetUserSendMethod operation.
     * @callback module:api/UserApi~resetUserSendMethodCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.sendType 
     * @param {module:api/UserApi~resetUserSendMethodCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    resetUserSendMethod(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'sendType': opts['sendType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/user/send-method/reset', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the saveUserPostmarkSendMethod operation.
     * @callback module:api/UserApi~saveUserPostmarkSendMethodCallback
     * @param {String} error Error message, if any.
     * @param {Number} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/UserApi~saveUserPostmarkSendMethodCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Number}
     */
    saveUserPostmarkSendMethod(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Number';
      return this.apiClient.callApi(
        '/user/send-method/settings/postmark', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the saveUserSmtpSendMethod operation.
     * @callback module:api/UserApi~saveUserSmtpSendMethodCallback
     * @param {String} error Error message, if any.
     * @param {Number} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SmtpServerInfo} opts.smtpServerInfo 
     * @param {module:api/UserApi~saveUserSmtpSendMethodCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Number}
     */
    saveUserSmtpSendMethod(opts, callback) {
      opts = opts || {};
      let postBody = opts['smtpServerInfo'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'Number';
      return this.apiClient.callApi(
        '/user/send-method/settings/smtp-provider', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setProductTourData operation.
     * @callback module:api/UserApi~setProductTourDataCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.requestBody 
     * @param {module:api/UserApi~setProductTourDataCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    setProductTourData(opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/user/product-tour/update-state', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateEmailSignature operation.
     * @callback module:api/UserApi~updateEmailSignatureCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {File} body Upload Binary
     * @param {module:api/UserApi~updateEmailSignatureCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    updateEmailSignature(userId, body, callback) {
      let postBody = body;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling updateEmailSignature");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateEmailSignature");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['text/html'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/user/{userId}/email-signature', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updatePassword operation.
     * @callback module:api/UserApi~updatePasswordCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdatePasswordRequest} opts.updatePasswordRequest 
     * @param {module:api/UserApi~updatePasswordCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    updatePassword(opts, callback) {
      opts = opts || {};
      let postBody = opts['updatePasswordRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/user/password/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updatePasswordViaToken operation.
     * @callback module:api/UserApi~updatePasswordViaTokenCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} validationHash 
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdatePasswordWithTokenRequest} opts.updatePasswordWithTokenRequest 
     * @param {module:api/UserApi~updatePasswordViaTokenCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    updatePasswordViaToken(validationHash, opts, callback) {
      opts = opts || {};
      let postBody = opts['updatePasswordWithTokenRequest'];
      // verify the required parameter 'validationHash' is set
      if (validationHash === undefined || validationHash === null) {
        throw new Error("Missing the required parameter 'validationHash' when calling updatePasswordViaToken");
      }

      let pathParams = {
        'validationHash': validationHash
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/user/password/update/{validationHash}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUser operation.
     * @callback module:api/UserApi~updateUserCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateUserRequest} opts.updateUserRequest 
     * @param {module:api/UserApi~updateUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    updateUser(userId, opts, callback) {
      opts = opts || {};
      let postBody = opts['updateUserRequest'];
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling updateUser");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/user/{userId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUserDefaultSettings operation.
     * @callback module:api/UserApi~updateUserDefaultSettingsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateUserDefaultSettingsRequest} opts.updateUserDefaultSettingsRequest 
     * @param {module:api/UserApi~updateUserDefaultSettingsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    updateUserDefaultSettings(userId, opts, callback) {
      opts = opts || {};
      let postBody = opts['updateUserDefaultSettingsRequest'];
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling updateUserDefaultSettings");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/user/{userId}/default-settings', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadProfileImage operation.
     * @callback module:api/UserApi~uploadProfileImageCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @param {module:api/UserApi~uploadProfileImageCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    uploadProfileImage(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/user/set-profile-image', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the validateAccountUser operation.
     * @callback module:api/UserApi~validateAccountUserCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} validationHash 
     * @param {module:api/UserApi~validateAccountUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    validateAccountUser(validationHash, callback) {
      let postBody = null;
      // verify the required parameter 'validationHash' is set
      if (validationHash === undefined || validationHash === null) {
        throw new Error("Missing the required parameter 'validationHash' when calling validateAccountUser");
      }

      let pathParams = {
        'validationHash': validationHash
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/user/validate/{validationHash}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
