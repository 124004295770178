/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AccountOption model module.
 * @module model/AccountOption
 * @version V1
 */
class AccountOption {
    /**
     * Constructs a new <code>AccountOption</code>.
     * @alias module:model/AccountOption
     */
    constructor() { 
        
        AccountOption.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountOption</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountOption} obj Optional instance to populate.
     * @return {module:model/AccountOption} The populated <code>AccountOption</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountOption();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('option')) {
                obj['option'] = ApiClient.convertToType(data['option'], 'String');
            }
            if (data.hasOwnProperty('jsonValue')) {
                obj['jsonValue'] = ApiClient.convertToType(data['jsonValue'], 'String');
            }
            if (data.hasOwnProperty('stringValue')) {
                obj['stringValue'] = ApiClient.convertToType(data['stringValue'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AccountOption.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
AccountOption.prototype['accountId'] = undefined;

/**
 * @member {String} option
 */
AccountOption.prototype['option'] = undefined;

/**
 * @member {String} jsonValue
 */
AccountOption.prototype['jsonValue'] = undefined;

/**
 * @member {String} stringValue
 */
AccountOption.prototype['stringValue'] = undefined;

/**
 * @member {String} type
 */
AccountOption.prototype['type'] = undefined;

/**
 * @member {Date} createdAt
 */
AccountOption.prototype['createdAt'] = undefined;

/**
 * @member {Date} updatedAt
 */
AccountOption.prototype['updatedAt'] = undefined;






export default AccountOption;

