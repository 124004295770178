/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The WebSocketManager model module.
 * @module model/WebSocketManager
 * @version V1
 */
class WebSocketManager {
    /**
     * Constructs a new <code>WebSocketManager</code>.
     * @alias module:model/WebSocketManager
     */
    constructor() { 
        
        WebSocketManager.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>WebSocketManager</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/WebSocketManager} obj Optional instance to populate.
     * @return {module:model/WebSocketManager} The populated <code>WebSocketManager</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WebSocketManager();

            if (data.hasOwnProperty('isWebSocketRequest')) {
                obj['isWebSocketRequest'] = ApiClient.convertToType(data['isWebSocketRequest'], 'Boolean');
            }
            if (data.hasOwnProperty('webSocketRequestedProtocols')) {
                obj['webSocketRequestedProtocols'] = ApiClient.convertToType(data['webSocketRequestedProtocols'], ['String']);
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} isWebSocketRequest
 */
WebSocketManager.prototype['isWebSocketRequest'] = undefined;

/**
 * @member {Array.<String>} webSocketRequestedProtocols
 */
WebSocketManager.prototype['webSocketRequestedProtocols'] = undefined;






export default WebSocketManager;

