/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ApiTokenType from './ApiTokenType';

/**
 * The ApiToken model module.
 * @module model/ApiToken
 * @version V1
 */
class ApiToken {
    /**
     * Constructs a new <code>ApiToken</code>.
     * @alias module:model/ApiToken
     */
    constructor() { 
        
        ApiToken.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ApiToken</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ApiToken} obj Optional instance to populate.
     * @return {module:model/ApiToken} The populated <code>ApiToken</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ApiToken();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
            if (data.hasOwnProperty('infoToken')) {
                obj['infoToken'] = ApiClient.convertToType(data['infoToken'], 'String');
            }
            if (data.hasOwnProperty('refreshToken')) {
                obj['refreshToken'] = ApiClient.convertToType(data['refreshToken'], 'String');
            }
            if (data.hasOwnProperty('resellerId')) {
                obj['resellerId'] = ApiClient.convertToType(data['resellerId'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('validTo')) {
                obj['validTo'] = ApiClient.convertToType(data['validTo'], 'Date');
            }
            if (data.hasOwnProperty('apiTokenType')) {
                obj['apiTokenType'] = ApiTokenType.constructFromObject(data['apiTokenType']);
            }
            if (data.hasOwnProperty('isTempToken')) {
                obj['isTempToken'] = ApiClient.convertToType(data['isTempToken'], 'Boolean');
            }
            if (data.hasOwnProperty('valid')) {
                obj['valid'] = ApiClient.convertToType(data['valid'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ApiToken.prototype['id'] = undefined;

/**
 * @member {String} token
 */
ApiToken.prototype['token'] = undefined;

/**
 * @member {String} infoToken
 */
ApiToken.prototype['infoToken'] = undefined;

/**
 * @member {String} refreshToken
 */
ApiToken.prototype['refreshToken'] = undefined;

/**
 * @member {Number} resellerId
 */
ApiToken.prototype['resellerId'] = undefined;

/**
 * @member {Number} accountId
 */
ApiToken.prototype['accountId'] = undefined;

/**
 * @member {Number} userId
 */
ApiToken.prototype['userId'] = undefined;

/**
 * @member {Number} approverId
 */
ApiToken.prototype['approverId'] = undefined;

/**
 * @member {Number} proofId
 */
ApiToken.prototype['proofId'] = undefined;

/**
 * @member {Date} validTo
 */
ApiToken.prototype['validTo'] = undefined;

/**
 * @member {module:model/ApiTokenType} apiTokenType
 */
ApiToken.prototype['apiTokenType'] = undefined;

/**
 * @member {Boolean} isTempToken
 */
ApiToken.prototype['isTempToken'] = undefined;

/**
 * @member {Boolean} valid
 */
ApiToken.prototype['valid'] = undefined;






export default ApiToken;

