/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CreateAnnotationRequest model module.
 * @module model/CreateAnnotationRequest
 * @version V1
 */
class CreateAnnotationRequest {
    /**
     * Constructs a new <code>CreateAnnotationRequest</code>.
     * @alias module:model/CreateAnnotationRequest
     */
    constructor() { 
        
        CreateAnnotationRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CreateAnnotationRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreateAnnotationRequest} obj Optional instance to populate.
     * @return {module:model/CreateAnnotationRequest} The populated <code>CreateAnnotationRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateAnnotationRequest();

            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('ownerName')) {
                obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
            }
            if (data.hasOwnProperty('ownerInitials')) {
                obj['ownerInitials'] = ApiClient.convertToType(data['ownerInitials'], 'String');
            }
            if (data.hasOwnProperty('proofId')) {
                obj['proofId'] = ApiClient.convertToType(data['proofId'], 'Number');
            }
            if (data.hasOwnProperty('proofFileId')) {
                obj['proofFileId'] = ApiClient.convertToType(data['proofFileId'], 'Number');
            }
            if (data.hasOwnProperty('proofVersionId')) {
                obj['proofVersionId'] = ApiClient.convertToType(data['proofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('docType')) {
                obj['docType'] = ApiClient.convertToType(data['docType'], 'String');
            }
            if (data.hasOwnProperty('pdfPage')) {
                obj['pdfPage'] = ApiClient.convertToType(data['pdfPage'], 'Number');
            }
            if (data.hasOwnProperty('curTime')) {
                obj['curTime'] = ApiClient.convertToType(data['curTime'], 'Number');
            }
            if (data.hasOwnProperty('containerWidth')) {
                obj['containerWidth'] = ApiClient.convertToType(data['containerWidth'], 'Number');
            }
            if (data.hasOwnProperty('zoom')) {
                obj['zoom'] = ApiClient.convertToType(data['zoom'], 'Number');
            }
            if (data.hasOwnProperty('x')) {
                obj['x'] = ApiClient.convertToType(data['x'], 'Number');
            }
            if (data.hasOwnProperty('y')) {
                obj['y'] = ApiClient.convertToType(data['y'], 'Number');
            }
            if (data.hasOwnProperty('x2')) {
                obj['x2'] = ApiClient.convertToType(data['x2'], 'Number');
            }
            if (data.hasOwnProperty('y2')) {
                obj['y2'] = ApiClient.convertToType(data['y2'], 'Number');
            }
            if (data.hasOwnProperty('startX')) {
                obj['startX'] = ApiClient.convertToType(data['startX'], 'Number');
            }
            if (data.hasOwnProperty('startY')) {
                obj['startY'] = ApiClient.convertToType(data['startY'], 'Number');
            }
            if (data.hasOwnProperty('endX')) {
                obj['endX'] = ApiClient.convertToType(data['endX'], 'Number');
            }
            if (data.hasOwnProperty('endY')) {
                obj['endY'] = ApiClient.convertToType(data['endY'], 'Number');
            }
            if (data.hasOwnProperty('w')) {
                obj['w'] = ApiClient.convertToType(data['w'], 'Number');
            }
            if (data.hasOwnProperty('h')) {
                obj['h'] = ApiClient.convertToType(data['h'], 'Number');
            }
            if (data.hasOwnProperty('liveWebProofingUrl')) {
                obj['liveWebProofingUrl'] = ApiClient.convertToType(data['liveWebProofingUrl'], 'String');
            }
            if (data.hasOwnProperty('liveWebProofingResolution')) {
                obj['liveWebProofingResolution'] = ApiClient.convertToType(data['liveWebProofingResolution'], 'Number');
            }
            if (data.hasOwnProperty('contextImageBase64Encoded')) {
                obj['contextImageBase64Encoded'] = ApiClient.convertToType(data['contextImageBase64Encoded'], 'String');
            }
            if (data.hasOwnProperty('drawingLayerImageBase64Encoded')) {
                obj['drawingLayerImageBase64Encoded'] = ApiClient.convertToType(data['drawingLayerImageBase64Encoded'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} accountId
 */
CreateAnnotationRequest.prototype['accountId'] = undefined;

/**
 * @member {Number} userId
 */
CreateAnnotationRequest.prototype['userId'] = undefined;

/**
 * @member {Number} approverId
 */
CreateAnnotationRequest.prototype['approverId'] = undefined;

/**
 * @member {String} ownerName
 */
CreateAnnotationRequest.prototype['ownerName'] = undefined;

/**
 * @member {String} ownerInitials
 */
CreateAnnotationRequest.prototype['ownerInitials'] = undefined;

/**
 * @member {Number} proofId
 */
CreateAnnotationRequest.prototype['proofId'] = undefined;

/**
 * @member {Number} proofFileId
 */
CreateAnnotationRequest.prototype['proofFileId'] = undefined;

/**
 * @member {Number} proofVersionId
 */
CreateAnnotationRequest.prototype['proofVersionId'] = undefined;

/**
 * @member {String} type
 */
CreateAnnotationRequest.prototype['type'] = undefined;

/**
 * @member {String} docType
 */
CreateAnnotationRequest.prototype['docType'] = undefined;

/**
 * @member {Number} pdfPage
 */
CreateAnnotationRequest.prototype['pdfPage'] = undefined;

/**
 * @member {Number} curTime
 */
CreateAnnotationRequest.prototype['curTime'] = undefined;

/**
 * @member {Number} containerWidth
 */
CreateAnnotationRequest.prototype['containerWidth'] = undefined;

/**
 * @member {Number} zoom
 */
CreateAnnotationRequest.prototype['zoom'] = undefined;

/**
 * @member {Number} x
 */
CreateAnnotationRequest.prototype['x'] = undefined;

/**
 * @member {Number} y
 */
CreateAnnotationRequest.prototype['y'] = undefined;

/**
 * @member {Number} x2
 */
CreateAnnotationRequest.prototype['x2'] = undefined;

/**
 * @member {Number} y2
 */
CreateAnnotationRequest.prototype['y2'] = undefined;

/**
 * @member {Number} startX
 */
CreateAnnotationRequest.prototype['startX'] = undefined;

/**
 * @member {Number} startY
 */
CreateAnnotationRequest.prototype['startY'] = undefined;

/**
 * @member {Number} endX
 */
CreateAnnotationRequest.prototype['endX'] = undefined;

/**
 * @member {Number} endY
 */
CreateAnnotationRequest.prototype['endY'] = undefined;

/**
 * @member {Number} w
 */
CreateAnnotationRequest.prototype['w'] = undefined;

/**
 * @member {Number} h
 */
CreateAnnotationRequest.prototype['h'] = undefined;

/**
 * @member {String} liveWebProofingUrl
 */
CreateAnnotationRequest.prototype['liveWebProofingUrl'] = undefined;

/**
 * @member {Number} liveWebProofingResolution
 */
CreateAnnotationRequest.prototype['liveWebProofingResolution'] = undefined;

/**
 * @member {String} contextImageBase64Encoded
 */
CreateAnnotationRequest.prototype['contextImageBase64Encoded'] = undefined;

/**
 * @member {String} drawingLayerImageBase64Encoded
 */
CreateAnnotationRequest.prototype['drawingLayerImageBase64Encoded'] = undefined;






export default CreateAnnotationRequest;

