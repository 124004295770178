/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import SaveTagFilterRequest from '../model/SaveTagFilterRequest';
import SaveTagRequest from '../model/SaveTagRequest';
import SuccessResponse from '../model/SuccessResponse';
import Tag from '../model/Tag';
import TagFilter from '../model/TagFilter';
import TagLink from '../model/TagLink';
import TagType from '../model/TagType';

/**
* Tag service.
* @module api/TagApi
* @version V1
*/
export default class TagApi {

    /**
    * Constructs a new TagApi. 
    * @alias module:api/TagApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createTag operation.
     * @callback module:api/TagApi~createTagCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Tag} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveTagRequest} opts.saveTagRequest 
     * @param {module:api/TagApi~createTagCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Tag}
     */
    createTag(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveTagRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Tag;
      return this.apiClient.callApi(
        '/tag', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createTagFilter operation.
     * @callback module:api/TagApi~createTagFilterCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TagFilter} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveTagFilterRequest} opts.saveTagFilterRequest 
     * @param {module:api/TagApi~createTagFilterCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TagFilter}
     */
    createTagFilter(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveTagFilterRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TagFilter;
      return this.apiClient.callApi(
        '/tag-filter', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the createTagLink operation.
     * @callback module:api/TagApi~createTagLinkCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TagLink} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.itemIdToTag 
     * @param {module:model/TagType} opts.tagType 
     * @param {module:api/TagApi~createTagLinkCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TagLink}
     */
    createTagLink(tagId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling createTagLink");
      }

      let pathParams = {
        'tagId': tagId
      };
      let queryParams = {
        'itemIdToTag': opts['itemIdToTag'],
        'tagType': opts['tagType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TagLink;
      return this.apiClient.callApi(
        '/tag/{tagId}/tagLink', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteTag operation.
     * @callback module:api/TagApi~deleteTagCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagId 
     * @param {module:api/TagApi~deleteTagCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteTag(tagId, callback) {
      let postBody = null;
      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling deleteTag");
      }

      let pathParams = {
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/tag/{tagId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteTagFilter operation.
     * @callback module:api/TagApi~deleteTagFilterCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagFilterId 
     * @param {module:api/TagApi~deleteTagFilterCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteTagFilter(tagFilterId, callback) {
      let postBody = null;
      // verify the required parameter 'tagFilterId' is set
      if (tagFilterId === undefined || tagFilterId === null) {
        throw new Error("Missing the required parameter 'tagFilterId' when calling deleteTagFilter");
      }

      let pathParams = {
        'tagFilterId': tagFilterId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/tag-filter/{tagFilterId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteTagLink operation.
     * @callback module:api/TagApi~deleteTagLinkCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagId 
     * @param {Number} tagLinkId 
     * @param {module:api/TagApi~deleteTagLinkCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteTagLink(tagId, tagLinkId, callback) {
      let postBody = null;
      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling deleteTagLink");
      }
      // verify the required parameter 'tagLinkId' is set
      if (tagLinkId === undefined || tagLinkId === null) {
        throw new Error("Missing the required parameter 'tagLinkId' when calling deleteTagLink");
      }

      let pathParams = {
        'tagId': tagId,
        'tagLinkId': tagLinkId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/tag/{tagId}/tagLink/{tagLinkId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteTagLinkByTaggedItemId operation.
     * @callback module:api/TagApi~deleteTagLinkByTaggedItemIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagId 
     * @param {Number} taggedItemId 
     * @param {module:model/TagType} tagType 
     * @param {module:api/TagApi~deleteTagLinkByTaggedItemIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteTagLinkByTaggedItemId(tagId, taggedItemId, tagType, callback) {
      let postBody = null;
      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling deleteTagLinkByTaggedItemId");
      }
      // verify the required parameter 'taggedItemId' is set
      if (taggedItemId === undefined || taggedItemId === null) {
        throw new Error("Missing the required parameter 'taggedItemId' when calling deleteTagLinkByTaggedItemId");
      }
      // verify the required parameter 'tagType' is set
      if (tagType === undefined || tagType === null) {
        throw new Error("Missing the required parameter 'tagType' when calling deleteTagLinkByTaggedItemId");
      }

      let pathParams = {
        'tagId': tagId,
        'taggedItemId': taggedItemId,
        'tagType': tagType
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/tag/{tagId}/tagType/{tagType}/taggedItemId/{taggedItemId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAll operation.
     * @callback module:api/TagApi~getAllCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Tag>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TagApi~getAllCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Tag>}
     */
    getAll(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [Tag];
      return this.apiClient.callApi(
        '/tag', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllTagFilters operation.
     * @callback module:api/TagApi~getAllTagFiltersCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/TagFilter>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TagApi~getAllTagFiltersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/TagFilter>}
     */
    getAllTagFilters(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [TagFilter];
      return this.apiClient.callApi(
        '/tag-filters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getTag operation.
     * @callback module:api/TagApi~getTagCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Tag} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagId 
     * @param {module:api/TagApi~getTagCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Tag}
     */
    getTag(tagId, callback) {
      let postBody = null;
      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling getTag");
      }

      let pathParams = {
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Tag;
      return this.apiClient.callApi(
        '/tag/{tagId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getTagFilter operation.
     * @callback module:api/TagApi~getTagFilterCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TagFilter} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagFilterId 
     * @param {module:api/TagApi~getTagFilterCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TagFilter}
     */
    getTagFilter(tagFilterId, callback) {
      let postBody = null;
      // verify the required parameter 'tagFilterId' is set
      if (tagFilterId === undefined || tagFilterId === null) {
        throw new Error("Missing the required parameter 'tagFilterId' when calling getTagFilter");
      }

      let pathParams = {
        'tagFilterId': tagFilterId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TagFilter;
      return this.apiClient.callApi(
        '/tag-filters/{tagFilterId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateTag operation.
     * @callback module:api/TagApi~updateTagCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Tag} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveTagRequest} opts.saveTagRequest 
     * @param {module:api/TagApi~updateTagCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Tag}
     */
    updateTag(tagId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveTagRequest'];
      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling updateTag");
      }

      let pathParams = {
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Tag;
      return this.apiClient.callApi(
        '/tag/{tagId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateTagFilter operation.
     * @callback module:api/TagApi~updateTagFilterCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TagFilter} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} tagFilterId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveTagFilterRequest} opts.saveTagFilterRequest 
     * @param {module:api/TagApi~updateTagFilterCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TagFilter}
     */
    updateTagFilter(tagFilterId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveTagFilterRequest'];
      // verify the required parameter 'tagFilterId' is set
      if (tagFilterId === undefined || tagFilterId === null) {
        throw new Error("Missing the required parameter 'tagFilterId' when calling updateTagFilter");
      }

      let pathParams = {
        'tagFilterId': tagFilterId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TagFilter;
      return this.apiClient.callApi(
        '/tag-filter/{tagFilterId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
