/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import ProofRequirement from '../model/ProofRequirement';
import SaveProofRequirementRequest from '../model/SaveProofRequirementRequest';
import SuccessResponse from '../model/SuccessResponse';

/**
* ProofRequirement service.
* @module api/ProofRequirementApi
* @version V1
*/
export default class ProofRequirementApi {

    /**
    * Constructs a new ProofRequirementApi. 
    * @alias module:api/ProofRequirementApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createProofRequirement operation.
     * @callback module:api/ProofRequirementApi~createProofRequirementCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofRequirement} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveProofRequirementRequest} opts.saveProofRequirementRequest 
     * @param {module:api/ProofRequirementApi~createProofRequirementCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofRequirement}
     */
    createProofRequirement(opts, callback) {
      opts = opts || {};
      let postBody = opts['saveProofRequirementRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofRequirement;
      return this.apiClient.callApi(
        '/proof-requirement', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteProofRequirement operation.
     * @callback module:api/ProofRequirementApi~deleteProofRequirementCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofRequirementId 
     * @param {module:api/ProofRequirementApi~deleteProofRequirementCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteProofRequirement(proofRequirementId, callback) {
      let postBody = null;
      // verify the required parameter 'proofRequirementId' is set
      if (proofRequirementId === undefined || proofRequirementId === null) {
        throw new Error("Missing the required parameter 'proofRequirementId' when calling deleteProofRequirement");
      }

      let pathParams = {
        'proofRequirementId': proofRequirementId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/proof-requirement/{proofRequirementId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllProofRequirements operation.
     * @callback module:api/ProofRequirementApi~getAllProofRequirementsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProofRequirement>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.workflowId  (default to 0)
     * @param {Number} opts.stageId  (default to 0)
     * @param {module:api/ProofRequirementApi~getAllProofRequirementsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProofRequirement>}
     */
    getAllProofRequirements(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'workflowId': opts['workflowId'],
        'stageId': opts['stageId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [ProofRequirement];
      return this.apiClient.callApi(
        '/proof-requirement', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateProofRequirement operation.
     * @callback module:api/ProofRequirementApi~updateProofRequirementCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofRequirement} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofRequirementId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SaveProofRequirementRequest} opts.saveProofRequirementRequest 
     * @param {module:api/ProofRequirementApi~updateProofRequirementCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofRequirement}
     */
    updateProofRequirement(proofRequirementId, opts, callback) {
      opts = opts || {};
      let postBody = opts['saveProofRequirementRequest'];
      // verify the required parameter 'proofRequirementId' is set
      if (proofRequirementId === undefined || proofRequirementId === null) {
        throw new Error("Missing the required parameter 'proofRequirementId' when calling updateProofRequirement");
      }

      let pathParams = {
        'proofRequirementId': proofRequirementId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofRequirement;
      return this.apiClient.callApi(
        '/proof-requirement/{proofRequirementId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
