import WorkflowApi from "../generated/api/WorkflowApi";
import SaveWorkflowRequest from "../generated/model/SaveWorkflowRequest";
import SaveWorkflowStageRequest from "../generated/model/SaveWorkflowStageRequest";

class WorkflowService {
    constructor() {
        this.WorkflowApi = new WorkflowApi();
    }

    /*Workflows*/
    async GetAll() {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.getAll(),
            "",
            true
        );
    }

    async Get(id) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.getWorkflow(id, null),
            "",
            true
        );
    }

    async Save(workflow) {
        if (workflow.id !== undefined && workflow.id > 0) {
            return await window.$A.HandleApiResponse(
                this.WorkflowApi.updateWorkflow(workflow.id, {
                    saveWorkflowRequest: new SaveWorkflowRequest(workflow.name),
                }),
                "Workflow " + workflow.name + " Saved!",
                true
            );
        } else {
            return await window.$A.HandleApiResponse(
                this.WorkflowApi.createWorkflow({
                    saveWorkflowRequest: new SaveWorkflowRequest(workflow.name),
                }),
                "Workflow " + workflow.name + " Saved!",
                true
            );
        }
    }

    async Delete(id) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.deleteWorkflow(id, null),
            "Workflow Deleted!"
        );
    }

    async Duplicate(id) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.duplicate(id, null),
            "Workflow Duplicated!"
        );
    }

    /*Workflow Stages*/
    async SaveWorkflowStage(workflowId, workflowStage) {
        if (!Array.isArray(workflowStage.bccUserIds)) {
            workflowStage.bccUserIds = [];
        }
        if (
            Number.isNaN(workflowStage.templateId) ||
            workflowStage.templateId === undefined
        ) {
            workflowStage.templateId = 0;
        }
        if (workflowStage.id !== undefined && workflowStage.id !== 0) {
            return await window.$A.HandleApiResponse(
                this.WorkflowApi.updateWorkflowStage(workflowId, workflowStage.id, {
                    saveWorkflowStageRequest: SaveWorkflowStageRequest.constructFromObject(
                        {
                            name: workflowStage.name,
                            senderUserId: workflowStage.senderUserId,
                            templateId: workflowStage.templateId,
                            subjectLine: workflowStage.subjectLine,
                            message: workflowStage.message,
                            bccUserIds: workflowStage.bccUserIds,
                            checklistId: workflowStage.checklistId
                        }
                    ),
                }),
                "Workflow " + workflowStage.name + " Saved!",
                true
            );
        } else {
            return await window.$A.HandleApiResponse(
                this.WorkflowApi.createWorkflowStage(workflowId, {
                    saveWorkflowStageRequest: SaveWorkflowStageRequest.constructFromObject(
                        {
                            name: workflowStage.name,
                            senderUserId: workflowStage.senderUserId,
                            templateId: workflowStage.templateId,
                            subjectLine: workflowStage.subjectLine,
                            message: workflowStage.message,
                            bccUserIds: workflowStage.bccUserIds,
                            checklistId: workflowStage.checklistId
                        }
                    ),
                }),
                "Workflow " + workflowStage.name + " Saved!",
                true
            );
        }
    }

    async DeleteWorkflowStage(workflowId, workflowStageId) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.deleteWorkflowStage(workflowId, workflowStageId, null),
            "Workflow Stage Deleted"
        );
    }

    async ReorderWorkflowStages(workflowId, stageIdsInOrder) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.reOrderWorkflowStages(
                workflowId,
                {requestBody: stageIdsInOrder},
                null
            ),
            "",
            true
        );
    }

    /*Workflow Approvers*/
    async CreateWorkflowApprover(workflowId, stageId, approverIds) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.createWorkflowApprover(
                workflowId,
                stageId,
                {requestBody: approverIds},
                null
            ),
            "",
            true
        );
    }

    async DeleteWorkflowApprover(workflowId, stageId, approverId) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.deleteWorkflowApprover(
                workflowId,
                stageId,
                approverId,
                null
            ),
            "",
            true
        );
    }

    async CreateWorkflowReviewer(workflowId, stageId, reviewerIds) {
        return await window.$A.HandleApiResponse(
            this.WorkflowApi.createWorkflowReviewer(
                workflowId,
                stageId,
                {requestBody: reviewerIds},
                null
            ),
            "",
            true
        );
    }
}

export default WorkflowService;
