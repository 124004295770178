/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import HostString from './HostString';
import HttpContext from './HttpContext';
import QueryString from './QueryString';
import Stream from './Stream';
import StringStringKeyValuePair from './StringStringKeyValuePair';
import StringStringValuesKeyValuePair from './StringStringValuesKeyValuePair';

/**
 * The HttpRequest model module.
 * @module model/HttpRequest
 * @version V1
 */
class HttpRequest {
    /**
     * Constructs a new <code>HttpRequest</code>.
     * @alias module:model/HttpRequest
     */
    constructor() { 
        
        HttpRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>HttpRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/HttpRequest} obj Optional instance to populate.
     * @return {module:model/HttpRequest} The populated <code>HttpRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new HttpRequest();

            if (data.hasOwnProperty('httpContext')) {
                obj['httpContext'] = HttpContext.constructFromObject(data['httpContext']);
            }
            if (data.hasOwnProperty('method')) {
                obj['method'] = ApiClient.convertToType(data['method'], 'String');
            }
            if (data.hasOwnProperty('scheme')) {
                obj['scheme'] = ApiClient.convertToType(data['scheme'], 'String');
            }
            if (data.hasOwnProperty('isHttps')) {
                obj['isHttps'] = ApiClient.convertToType(data['isHttps'], 'Boolean');
            }
            if (data.hasOwnProperty('host')) {
                obj['host'] = HostString.constructFromObject(data['host']);
            }
            if (data.hasOwnProperty('pathBase')) {
                obj['pathBase'] = ApiClient.convertToType(data['pathBase'], 'String');
            }
            if (data.hasOwnProperty('path')) {
                obj['path'] = ApiClient.convertToType(data['path'], 'String');
            }
            if (data.hasOwnProperty('queryString')) {
                obj['queryString'] = QueryString.constructFromObject(data['queryString']);
            }
            if (data.hasOwnProperty('query')) {
                obj['query'] = ApiClient.convertToType(data['query'], [StringStringValuesKeyValuePair]);
            }
            if (data.hasOwnProperty('protocol')) {
                obj['protocol'] = ApiClient.convertToType(data['protocol'], 'String');
            }
            if (data.hasOwnProperty('headers')) {
                obj['headers'] = ApiClient.convertToType(data['headers'], {'String': ['String']});
            }
            if (data.hasOwnProperty('cookies')) {
                obj['cookies'] = ApiClient.convertToType(data['cookies'], [StringStringKeyValuePair]);
            }
            if (data.hasOwnProperty('contentLength')) {
                obj['contentLength'] = ApiClient.convertToType(data['contentLength'], 'Number');
            }
            if (data.hasOwnProperty('contentType')) {
                obj['contentType'] = ApiClient.convertToType(data['contentType'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = Stream.constructFromObject(data['body']);
            }
            if (data.hasOwnProperty('bodyReader')) {
                obj['bodyReader'] = ApiClient.convertToType(data['bodyReader'], Object);
            }
            if (data.hasOwnProperty('hasFormContentType')) {
                obj['hasFormContentType'] = ApiClient.convertToType(data['hasFormContentType'], 'Boolean');
            }
            if (data.hasOwnProperty('form')) {
                obj['form'] = ApiClient.convertToType(data['form'], [StringStringValuesKeyValuePair]);
            }
            if (data.hasOwnProperty('routeValues')) {
                obj['routeValues'] = ApiClient.convertToType(data['routeValues'], {'String': Object});
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/HttpContext} httpContext
 */
HttpRequest.prototype['httpContext'] = undefined;

/**
 * @member {String} method
 */
HttpRequest.prototype['method'] = undefined;

/**
 * @member {String} scheme
 */
HttpRequest.prototype['scheme'] = undefined;

/**
 * @member {Boolean} isHttps
 */
HttpRequest.prototype['isHttps'] = undefined;

/**
 * @member {module:model/HostString} host
 */
HttpRequest.prototype['host'] = undefined;

/**
 * @member {String} pathBase
 */
HttpRequest.prototype['pathBase'] = undefined;

/**
 * @member {String} path
 */
HttpRequest.prototype['path'] = undefined;

/**
 * @member {module:model/QueryString} queryString
 */
HttpRequest.prototype['queryString'] = undefined;

/**
 * @member {Array.<module:model/StringStringValuesKeyValuePair>} query
 */
HttpRequest.prototype['query'] = undefined;

/**
 * @member {String} protocol
 */
HttpRequest.prototype['protocol'] = undefined;

/**
 * @member {Object.<String, Array.<String>>} headers
 */
HttpRequest.prototype['headers'] = undefined;

/**
 * @member {Array.<module:model/StringStringKeyValuePair>} cookies
 */
HttpRequest.prototype['cookies'] = undefined;

/**
 * @member {Number} contentLength
 */
HttpRequest.prototype['contentLength'] = undefined;

/**
 * @member {String} contentType
 */
HttpRequest.prototype['contentType'] = undefined;

/**
 * @member {module:model/Stream} body
 */
HttpRequest.prototype['body'] = undefined;

/**
 * @member {Object} bodyReader
 */
HttpRequest.prototype['bodyReader'] = undefined;

/**
 * @member {Boolean} hasFormContentType
 */
HttpRequest.prototype['hasFormContentType'] = undefined;

/**
 * @member {Array.<module:model/StringStringValuesKeyValuePair>} form
 */
HttpRequest.prototype['form'] = undefined;

/**
 * @member {Object.<String, Object>} routeValues
 */
HttpRequest.prototype['routeValues'] = undefined;






export default HttpRequest;

