/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SmtpServerInfo from './SmtpServerInfo';

/**
 * The EmailSendData model module.
 * @module model/EmailSendData
 * @version V1
 */
class EmailSendData {
    /**
     * Constructs a new <code>EmailSendData</code>.
     * @alias module:model/EmailSendData
     */
    constructor() { 
        
        EmailSendData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>EmailSendData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/EmailSendData} obj Optional instance to populate.
     * @return {module:model/EmailSendData} The populated <code>EmailSendData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EmailSendData();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'Number');
            }
            if (data.hasOwnProperty('isSelected')) {
                obj['isSelected'] = ApiClient.convertToType(data['isSelected'], 'Boolean');
            }
            if (data.hasOwnProperty('validated')) {
                obj['validated'] = ApiClient.convertToType(data['validated'], 'Boolean');
            }
            if (data.hasOwnProperty('settingId')) {
                obj['settingId'] = ApiClient.convertToType(data['settingId'], 'Number');
            }
            if (data.hasOwnProperty('validationHash')) {
                obj['validationHash'] = ApiClient.convertToType(data['validationHash'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('fromEmail')) {
                obj['fromEmail'] = ApiClient.convertToType(data['fromEmail'], 'String');
            }
            if (data.hasOwnProperty('smtpServerInfo')) {
                obj['smtpServerInfo'] = SmtpServerInfo.constructFromObject(data['smtpServerInfo']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
EmailSendData.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
EmailSendData.prototype['accountId'] = undefined;

/**
 * @member {Number} userId
 */
EmailSendData.prototype['userId'] = undefined;

/**
 * @member {Number} type
 */
EmailSendData.prototype['type'] = undefined;

/**
 * @member {Boolean} isSelected
 */
EmailSendData.prototype['isSelected'] = undefined;

/**
 * @member {Boolean} validated
 */
EmailSendData.prototype['validated'] = undefined;

/**
 * @member {Number} settingId
 */
EmailSendData.prototype['settingId'] = undefined;

/**
 * @member {String} validationHash
 */
EmailSendData.prototype['validationHash'] = undefined;

/**
 * @member {Date} createdAt
 */
EmailSendData.prototype['createdAt'] = undefined;

/**
 * @member {String} fromEmail
 */
EmailSendData.prototype['fromEmail'] = undefined;

/**
 * @member {module:model/SmtpServerInfo} smtpServerInfo
 */
EmailSendData.prototype['smtpServerInfo'] = undefined;






export default EmailSendData;

