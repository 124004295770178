/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AppEvent from './AppEvent';
import BasicUserInfo from './BasicUserInfo';
import ProofCustomField from './ProofCustomField';
import ProofVersion from './ProofVersion';
import ProofVersionIndex from './ProofVersionIndex';
import ProofVersionStatus from './ProofVersionStatus';
import SendOptions from './SendOptions';
import Tag from './Tag';
import Workflow from './Workflow';

/**
 * The Proof model module.
 * @module model/Proof
 * @version V1
 */
class Proof {
    /**
     * Constructs a new <code>Proof</code>.
     * @alias module:model/Proof
     */
    constructor() { 
        
        Proof.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Proof</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Proof} obj Optional instance to populate.
     * @return {module:model/Proof} The populated <code>Proof</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Proof();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('publicId')) {
                obj['publicId'] = ApiClient.convertToType(data['publicId'], 'String');
            }
            if (data.hasOwnProperty('reviewerPublicId')) {
                obj['reviewerPublicId'] = ApiClient.convertToType(data['reviewerPublicId'], 'String');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('workflowId')) {
                obj['workflowId'] = ApiClient.convertToType(data['workflowId'], 'Number');
            }
            if (data.hasOwnProperty('templateWorkflowId')) {
                obj['templateWorkflowId'] = ApiClient.convertToType(data['templateWorkflowId'], 'Number');
            }
            if (data.hasOwnProperty('currentProofVersionId')) {
                obj['currentProofVersionId'] = ApiClient.convertToType(data['currentProofVersionId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('dueDate')) {
                obj['dueDate'] = ApiClient.convertToType(data['dueDate'], 'Date');
            }
            if (data.hasOwnProperty('approved')) {
                obj['approved'] = ApiClient.convertToType(data['approved'], 'Boolean');
            }
            if (data.hasOwnProperty('allowDownload')) {
                obj['allowDownload'] = ApiClient.convertToType(data['allowDownload'], 'Boolean');
            }
            if (data.hasOwnProperty('allowEdit')) {
                obj['allowEdit'] = ApiClient.convertToType(data['allowEdit'], 'Boolean');
            }
            if (data.hasOwnProperty('allowApproveWithChanges')) {
                obj['allowApproveWithChanges'] = ApiClient.convertToType(data['allowApproveWithChanges'], 'Boolean');
            }
            if (data.hasOwnProperty('allowViewAllComments')) {
                obj['allowViewAllComments'] = ApiClient.convertToType(data['allowViewAllComments'], 'Boolean');
            }
            if (data.hasOwnProperty('allowApproveIndividualFiles')) {
                obj['allowApproveIndividualFiles'] = ApiClient.convertToType(data['allowApproveIndividualFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('blockCommentingAfterProofDecision')) {
                obj['blockCommentingAfterProofDecision'] = ApiClient.convertToType(data['blockCommentingAfterProofDecision'], 'Boolean');
            }
            if (data.hasOwnProperty('limitVersionVisibility')) {
                obj['limitVersionVisibility'] = ApiClient.convertToType(data['limitVersionVisibility'], 'Boolean');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('primaryThumbNailCdnKey')) {
                obj['primaryThumbNailCdnKey'] = ApiClient.convertToType(data['primaryThumbNailCdnKey'], 'String');
            }
            if (data.hasOwnProperty('externalMetaData')) {
                obj['externalMetaData'] = ApiClient.convertToType(data['externalMetaData'], {'String': Object});
            }
            if (data.hasOwnProperty('teamsWithViewAccess')) {
                obj['teamsWithViewAccess'] = ApiClient.convertToType(data['teamsWithViewAccess'], ['Number']);
            }
            if (data.hasOwnProperty('proofThumbNailUri')) {
                obj['proofThumbNailUri'] = ApiClient.convertToType(data['proofThumbNailUri'], 'String');
            }
            if (data.hasOwnProperty('approverUri')) {
                obj['approverUri'] = ApiClient.convertToType(data['approverUri'], 'String');
            }
            if (data.hasOwnProperty('reviewUri')) {
                obj['reviewUri'] = ApiClient.convertToType(data['reviewUri'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ProofVersionStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('currentWorkflowStageId')) {
                obj['currentWorkflowStageId'] = ApiClient.convertToType(data['currentWorkflowStageId'], 'Number');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('deleted')) {
                obj['deleted'] = ApiClient.convertToType(data['deleted'], 'Boolean');
            }
            if (data.hasOwnProperty('archived')) {
                obj['archived'] = ApiClient.convertToType(data['archived'], 'Boolean');
            }
            if (data.hasOwnProperty('proofItemPageUrl')) {
                obj['proofItemPageUrl'] = ApiClient.convertToType(data['proofItemPageUrl'], 'String');
            }
            if (data.hasOwnProperty('currentVersion')) {
                obj['currentVersion'] = ProofVersion.constructFromObject(data['currentVersion']);
            }
            if (data.hasOwnProperty('versions')) {
                obj['versions'] = ApiClient.convertToType(data['versions'], [ProofVersion]);
            }
            if (data.hasOwnProperty('versionIds')) {
                obj['versionIds'] = ApiClient.convertToType(data['versionIds'], [ProofVersionIndex]);
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [Tag]);
            }
            if (data.hasOwnProperty('currentWorkflow')) {
                obj['currentWorkflow'] = Workflow.constructFromObject(data['currentWorkflow']);
            }
            if (data.hasOwnProperty('proofEvents')) {
                obj['proofEvents'] = ApiClient.convertToType(data['proofEvents'], [AppEvent]);
            }
            if (data.hasOwnProperty('engagedUsers')) {
                obj['engagedUsers'] = ApiClient.convertToType(data['engagedUsers'], [BasicUserInfo]);
            }
            if (data.hasOwnProperty('customFields')) {
                obj['customFields'] = ApiClient.convertToType(data['customFields'], [ProofCustomField]);
            }
            if (data.hasOwnProperty('lastSendOption')) {
                obj['lastSendOption'] = SendOptions.constructFromObject(data['lastSendOption']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Proof.prototype['id'] = undefined;

/**
 * @member {String} publicId
 */
Proof.prototype['publicId'] = undefined;

/**
 * @member {String} reviewerPublicId
 */
Proof.prototype['reviewerPublicId'] = undefined;

/**
 * @member {Number} accountId
 */
Proof.prototype['accountId'] = undefined;

/**
 * @member {Number} workflowId
 */
Proof.prototype['workflowId'] = undefined;

/**
 * @member {Number} templateWorkflowId
 */
Proof.prototype['templateWorkflowId'] = undefined;

/**
 * @member {Number} currentProofVersionId
 */
Proof.prototype['currentProofVersionId'] = undefined;

/**
 * @member {String} name
 */
Proof.prototype['name'] = undefined;

/**
 * @member {Date} dueDate
 */
Proof.prototype['dueDate'] = undefined;

/**
 * @member {Boolean} approved
 */
Proof.prototype['approved'] = undefined;

/**
 * @member {Boolean} allowDownload
 */
Proof.prototype['allowDownload'] = undefined;

/**
 * @member {Boolean} allowEdit
 */
Proof.prototype['allowEdit'] = undefined;

/**
 * @member {Boolean} allowApproveWithChanges
 */
Proof.prototype['allowApproveWithChanges'] = undefined;

/**
 * @member {Boolean} allowViewAllComments
 */
Proof.prototype['allowViewAllComments'] = undefined;

/**
 * @member {Boolean} allowApproveIndividualFiles
 */
Proof.prototype['allowApproveIndividualFiles'] = undefined;

/**
 * @member {Boolean} blockCommentingAfterProofDecision
 */
Proof.prototype['blockCommentingAfterProofDecision'] = undefined;

/**
 * @member {Boolean} limitVersionVisibility
 */
Proof.prototype['limitVersionVisibility'] = undefined;

/**
 * @member {String} password
 */
Proof.prototype['password'] = undefined;

/**
 * @member {String} primaryThumbNailCdnKey
 */
Proof.prototype['primaryThumbNailCdnKey'] = undefined;

/**
 * @member {Object.<String, Object>} externalMetaData
 */
Proof.prototype['externalMetaData'] = undefined;

/**
 * @member {Array.<Number>} teamsWithViewAccess
 */
Proof.prototype['teamsWithViewAccess'] = undefined;

/**
 * @member {String} proofThumbNailUri
 */
Proof.prototype['proofThumbNailUri'] = undefined;

/**
 * @member {String} approverUri
 */
Proof.prototype['approverUri'] = undefined;

/**
 * @member {String} reviewUri
 */
Proof.prototype['reviewUri'] = undefined;

/**
 * @member {module:model/ProofVersionStatus} status
 */
Proof.prototype['status'] = undefined;

/**
 * @member {Number} currentWorkflowStageId
 */
Proof.prototype['currentWorkflowStageId'] = undefined;

/**
 * @member {Date} createdAt
 */
Proof.prototype['createdAt'] = undefined;

/**
 * @member {Boolean} deleted
 */
Proof.prototype['deleted'] = undefined;

/**
 * @member {Boolean} archived
 */
Proof.prototype['archived'] = undefined;

/**
 * @member {String} proofItemPageUrl
 */
Proof.prototype['proofItemPageUrl'] = undefined;

/**
 * @member {module:model/ProofVersion} currentVersion
 */
Proof.prototype['currentVersion'] = undefined;

/**
 * @member {Array.<module:model/ProofVersion>} versions
 */
Proof.prototype['versions'] = undefined;

/**
 * @member {Array.<module:model/ProofVersionIndex>} versionIds
 */
Proof.prototype['versionIds'] = undefined;

/**
 * @member {Array.<module:model/Tag>} tags
 */
Proof.prototype['tags'] = undefined;

/**
 * @member {module:model/Workflow} currentWorkflow
 */
Proof.prototype['currentWorkflow'] = undefined;

/**
 * @member {Array.<module:model/AppEvent>} proofEvents
 */
Proof.prototype['proofEvents'] = undefined;

/**
 * @member {Array.<module:model/BasicUserInfo>} engagedUsers
 */
Proof.prototype['engagedUsers'] = undefined;

/**
 * @member {Array.<module:model/ProofCustomField>} customFields
 */
Proof.prototype['customFields'] = undefined;

/**
 * @member {module:model/SendOptions} lastSendOption
 */
Proof.prototype['lastSendOption'] = undefined;






export default Proof;

