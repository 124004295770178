/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import ProofVersionFile from '../model/ProofVersionFile';
import RedactorFile from '../model/RedactorFile';
import UploadFileFromUrlRequest from '../model/UploadFileFromUrlRequest';

/**
* ProofFile service.
* @module api/ProofFileApi
* @version V1
*/
export default class ProofFileApi {

    /**
    * Constructs a new ProofFileApi. 
    * @alias module:api/ProofFileApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the captureUrlAsProofFile operation.
     * @callback module:api/ProofFileApi~captureUrlAsProofFileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofVersionFile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isLiveWebProofing  (default to false)
     * @param {String} opts.body 
     * @param {module:api/ProofFileApi~captureUrlAsProofFileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofVersionFile}
     */
    captureUrlAsProofFile(proofId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling captureUrlAsProofFile");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling captureUrlAsProofFile");
      }

      let pathParams = {
        'proofId': proofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
        'isLiveWebProofing': opts['isLiveWebProofing']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofVersionFile;
      return this.apiClient.callApi(
        '/proof/{proofId}/proof-version/{proofVersionId}/url', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteProofFile operation.
     * @callback module:api/ProofFileApi~deleteProofFileCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} proofVersionId 
     * @param {Number} proofVersionFileId 
     * @param {module:api/ProofFileApi~deleteProofFileCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteProofFile(proofId, proofVersionId, proofVersionFileId, callback) {
      let postBody = null;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling deleteProofFile");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling deleteProofFile");
      }
      // verify the required parameter 'proofVersionFileId' is set
      if (proofVersionFileId === undefined || proofVersionFileId === null) {
        throw new Error("Missing the required parameter 'proofVersionFileId' when calling deleteProofFile");
      }

      let pathParams = {
        'proofId': proofId,
        'proofVersionId': proofVersionId,
        'proofVersionFileId': proofVersionFileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/proof/{proofId}/proof-version/{proofVersionId}/file/{proofVersionFileId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadAllFromProofFileVersion operation.
     * @callback module:api/ProofFileApi~downloadAllFromProofFileVersionCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} proofPublicId 
     * @param {Number} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.a  (default to '')
     * @param {String} opts.u  (default to '')
     * @param {module:api/ProofFileApi~downloadAllFromProofFileVersionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    downloadAllFromProofFileVersion(proofPublicId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'proofPublicId' is set
      if (proofPublicId === undefined || proofPublicId === null) {
        throw new Error("Missing the required parameter 'proofPublicId' when calling downloadAllFromProofFileVersion");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling downloadAllFromProofFileVersion");
      }

      let pathParams = {
        'proofPublicId': proofPublicId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
        'a': opts['a'],
        'u': opts['u']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/pfv/z/a/{proofPublicId}/{proofVersionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadSingleFileFromProofFileVersion operation.
     * @callback module:api/ProofFileApi~downloadSingleFileFromProofFileVersionCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} proofPublicId 
     * @param {Number} proofVersionId 
     * @param {Number} proofVersionFileId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.a  (default to '')
     * @param {String} opts.u  (default to '')
     * @param {module:api/ProofFileApi~downloadSingleFileFromProofFileVersionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    downloadSingleFileFromProofFileVersion(proofPublicId, proofVersionId, proofVersionFileId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'proofPublicId' is set
      if (proofPublicId === undefined || proofPublicId === null) {
        throw new Error("Missing the required parameter 'proofPublicId' when calling downloadSingleFileFromProofFileVersion");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling downloadSingleFileFromProofFileVersion");
      }
      // verify the required parameter 'proofVersionFileId' is set
      if (proofVersionFileId === undefined || proofVersionFileId === null) {
        throw new Error("Missing the required parameter 'proofVersionFileId' when calling downloadSingleFileFromProofFileVersion");
      }

      let pathParams = {
        'proofPublicId': proofPublicId,
        'proofVersionId': proofVersionId,
        'proofVersionFileId': proofVersionFileId
      };
      let queryParams = {
        'a': opts['a'],
        'u': opts['u']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/pfv/z/a/{proofPublicId}/{proofVersionId}/{proofVersionFileId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the longPollForThumbnailUpdate operation.
     * @callback module:api/ProofFileApi~longPollForThumbnailUpdateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofVersionFile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofVersionFileId 
     * @param {module:api/ProofFileApi~longPollForThumbnailUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofVersionFile}
     */
    longPollForThumbnailUpdate(proofVersionFileId, callback) {
      let postBody = null;
      // verify the required parameter 'proofVersionFileId' is set
      if (proofVersionFileId === undefined || proofVersionFileId === null) {
        throw new Error("Missing the required parameter 'proofVersionFileId' when calling longPollForThumbnailUpdate");
      }

      let pathParams = {
        'proofVersionFileId': proofVersionFileId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofVersionFile;
      return this.apiClient.callApi(
        '/proof-version-file/{proofVersionFileId}/long-poll-for-thumbnail', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateOrderForProofVersionFiles operation.
     * @callback module:api/ProofFileApi~updateOrderForProofVersionFilesCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Upload an array of proof version file Ids in the order you wish to save them
     * @param {Number} proofId 
     * @param {Number} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.requestBody 
     * @param {module:api/ProofFileApi~updateOrderForProofVersionFilesCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateOrderForProofVersionFiles(proofId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling updateOrderForProofVersionFiles");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling updateOrderForProofVersionFiles");
      }

      let pathParams = {
        'proofId': proofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/proof/{proofId}/proof-version/{proofVersionId}/reorder', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadFileFromUrl operation.
     * @callback module:api/ProofFileApi~uploadFileFromUrlCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofVersionFile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} proofVersionId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UploadFileFromUrlRequest} opts.uploadFileFromUrlRequest 
     * @param {module:api/ProofFileApi~uploadFileFromUrlCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofVersionFile}
     */
    uploadFileFromUrl(proofId, proofVersionId, opts, callback) {
      opts = opts || {};
      let postBody = opts['uploadFileFromUrlRequest'];
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling uploadFileFromUrl");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling uploadFileFromUrl");
      }

      let pathParams = {
        'proofId': proofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofVersionFile;
      return this.apiClient.callApi(
        '/proof/{proofId}/proof-version/{proofVersionId}/file-from-url', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadProofCommentFile operation.
     * @callback module:api/ProofFileApi~uploadProofCommentFileCallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, module:model/{String: RedactorFile}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} publicProofId 
     * @param {File} body Upload Binary
     * @param {module:api/ProofFileApi~uploadProofCommentFileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, module:model/{String: RedactorFile}>}
     */
    uploadProofCommentFile(publicProofId, body, callback) {
      let postBody = body;
      // verify the required parameter 'publicProofId' is set
      if (publicProofId === undefined || publicProofId === null) {
        throw new Error("Missing the required parameter 'publicProofId' when calling uploadProofCommentFile");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling uploadProofCommentFile");
      }

      let pathParams = {
        'publicProofId': publicProofId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = {'String': RedactorFile};
      return this.apiClient.callApi(
        '/proof/message-file/{publicProofId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadProofFile operation.
     * @callback module:api/ProofFileApi~uploadProofFileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProofVersionFile} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} proofId 
     * @param {Number} proofVersionId 
     * @param {File} body Upload Binary
     * @param {module:api/ProofFileApi~uploadProofFileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProofVersionFile}
     */
    uploadProofFile(proofId, proofVersionId, body, callback) {
      let postBody = body;
      // verify the required parameter 'proofId' is set
      if (proofId === undefined || proofId === null) {
        throw new Error("Missing the required parameter 'proofId' when calling uploadProofFile");
      }
      // verify the required parameter 'proofVersionId' is set
      if (proofVersionId === undefined || proofVersionId === null) {
        throw new Error("Missing the required parameter 'proofVersionId' when calling uploadProofFile");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling uploadProofFile");
      }

      let pathParams = {
        'proofId': proofId,
        'proofVersionId': proofVersionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProofVersionFile;
      return this.apiClient.callApi(
        '/proof/{proofId}/proof-version/{proofVersionId}/file', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
