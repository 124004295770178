/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProofCommentMention model module.
 * @module model/ProofCommentMention
 * @version V1
 */
class ProofCommentMention {
    /**
     * Constructs a new <code>ProofCommentMention</code>.
     * @alias module:model/ProofCommentMention
     */
    constructor() { 
        
        ProofCommentMention.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProofCommentMention</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProofCommentMention} obj Optional instance to populate.
     * @return {module:model/ProofCommentMention} The populated <code>ProofCommentMention</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProofCommentMention();

            if (data.hasOwnProperty('approverId')) {
                obj['approverId'] = ApiClient.convertToType(data['approverId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'String');
            }
            if (data.hasOwnProperty('fullText')) {
                obj['fullText'] = ApiClient.convertToType(data['fullText'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} approverId
 */
ProofCommentMention.prototype['approverId'] = undefined;

/**
 * @member {Number} userId
 */
ProofCommentMention.prototype['userId'] = undefined;

/**
 * @member {String} value
 */
ProofCommentMention.prototype['value'] = undefined;

/**
 * @member {String} fullText
 */
ProofCommentMention.prototype['fullText'] = undefined;






export default ProofCommentMention;

