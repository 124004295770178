/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ActionDescriptor from './ActionDescriptor';
import HttpContext from './HttpContext';
import ModelStateEntry from './ModelStateEntry';
import RouteData from './RouteData';

/**
 * The ActionContext model module.
 * @module model/ActionContext
 * @version V1
 */
class ActionContext {
    /**
     * Constructs a new <code>ActionContext</code>.
     * @alias module:model/ActionContext
     */
    constructor() { 
        
        ActionContext.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ActionContext</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ActionContext} obj Optional instance to populate.
     * @return {module:model/ActionContext} The populated <code>ActionContext</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ActionContext();

            if (data.hasOwnProperty('actionDescriptor')) {
                obj['actionDescriptor'] = ActionDescriptor.constructFromObject(data['actionDescriptor']);
            }
            if (data.hasOwnProperty('httpContext')) {
                obj['httpContext'] = HttpContext.constructFromObject(data['httpContext']);
            }
            if (data.hasOwnProperty('modelState')) {
                obj['modelState'] = ApiClient.convertToType(data['modelState'], {'String': ModelStateEntry});
            }
            if (data.hasOwnProperty('routeData')) {
                obj['routeData'] = RouteData.constructFromObject(data['routeData']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/ActionDescriptor} actionDescriptor
 */
ActionContext.prototype['actionDescriptor'] = undefined;

/**
 * @member {module:model/HttpContext} httpContext
 */
ActionContext.prototype['httpContext'] = undefined;

/**
 * @member {Object.<String, module:model/ModelStateEntry>} modelState
 */
ActionContext.prototype['modelState'] = undefined;

/**
 * @member {module:model/RouteData} routeData
 */
ActionContext.prototype['routeData'] = undefined;






export default ActionContext;

