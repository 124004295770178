<template>
  <div id="root_container">
    <router-view v-if="isAuthenticated"></router-view>
  </div>
</template>

<script>
import { isCustomDomain, updateFavicon } from "./utils/helpers";

export default {
  name: "app",
  data() {
    return {
      isAuthenticated: false,
      authRoutes: [
        "/proof-item",
        "/overview",
        "/contact-archive",
        "/approver-item",
        "/automation-archive",
        "/template-item",
        "/workflow-item",
        "/proof-archive",
        "/create",
        "/profile",
        "/profile",
      ],
      adminRoutes: ["/account", "/billing", "/users"],
      superAdminRoutes: ["/captains-deck"],
      isCustomDomain
    };
  },
  beforeMount: function(){
    this.authCheck();
  },
  mounted: async function () {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    let accountLogo = null; 
    if (this.isCustomDomain) accountLogo = await $A.UserService.GetAccountLogo(window.location.hostname) || 'img/logo-large.svg';
    else accountLogo = _.get(this, '$A.Auth.account.whiteLabelLogoUri',  'img/logo-large.svg');

    if (accountLogo) {
      this.updateFavicon(accountLogo);
    }

    if (this.$A.Auth) {
      this.$A.MarketingService.SSOPostLoginAttributionCheck(
          this.$A.Auth.name,
          this.$A.Auth.account.name,
          this.$A.Auth.email,
          this.$A.Auth.title,
          this.$A.Auth.phone,
      );
    }
  },
  methods: {
    authCheck: async function () {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      let currentUser = this.$A.Auth;
      let path = this.$route.path;
      let query = this.$route.query;

      let needsSecurity = false;
      for (let i = 0; i < this.authRoutes.length; i++) {
        let r = this.authRoutes[i];
        if (path === r) {
          needsSecurity = true;
        }
      }

      this.$A.CheckIfShouldNavigateToView();
      if(params.tl){
        let ld = atob(params.tl);
        //log them in with their temp token
        let token = ld.replace("\n","")
        await $A.UserService.LoginWithToken(token, true);
        currentUser = this.$A.Auth
      }
      if (params.g !== undefined && currentUser !== null) {
        let linkdata = atob(params.g).split(",");
          if (
              linkdata.length === 2 &&
              linkdata[0] === "proof-item" &&
              linkdata[1] > 0
          ) {
            let self = this;
            sessionStorage.viewToViewData = JSON.stringify({
              proof: { id: parseInt(linkdata[1]) },
            });
            window.location = "/" + linkdata[0];
            this.isAuthenticated = true;
            return;
          }
          if (
              linkdata.length === 3 &&
              linkdata[0] === "review-page" &&
              linkdata[2] > 0
          ) {
            let self = this;
            sessionStorage.viewToViewData = JSON.stringify({
              proof: { id: parseInt(linkdata[2]) },
            });
            window.location = "/review/" + linkdata[1];
            this.isAuthenticated = true;
            return;
          }
      }

      if (window.location &&
          window.location.href &&
          !window.location.href.includes('/review') && currentUser !== null) {
        //if this call fails the user will be logged out.
        let auth = await this.$A.AccountService.Get();
        // window.location = "/overview";
        // this.$router.push("/overview");
        this.isAuthenticated = true;
        // return;
      }



      if (currentUser == null) {
        this.isAuthenticated = true;
        return;
      }

      //check to see if a sub user is trying to access an admin page if so redirect to overview
      if (!currentUser.permissions.manageAccount) {
        for (let i = 0; i < this.adminRoutes.length; i++) {
          let ar = this.adminRoutes[i];
          if (path === ar) {
            window.location = "/403";
            this.isAuthenticated = true;
            return;
          }
        }
      }
      //check to see if a sub user is trying to access an admin page if so redirect to overview
      if (!currentUser.permissions.role === 3) {
        for (let i = 0; i < this.superAdminRoutes.length; i++) {
          let ar = this.superAdminRoutes[i];
          if (path === ar) {
            window.location = "/403";
            this.isAuthenticated = true;
            return;
          }
        }
      }
      this.isAuthenticated = true;
    },
    updateFavicon
  },
};
</script>
<style>
#root_container {
}
</style>
