/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import AshoreUsersTeam from '../model/AshoreUsersTeam';
import ReAssignUsersTeamRequest from '../model/ReAssignUsersTeamRequest';
import SuccessResponse from '../model/SuccessResponse';
import UpdateTeamRequest from '../model/UpdateTeamRequest';
import WriteTeamRequest from '../model/WriteTeamRequest';

/**
* Teams service.
* @module api/TeamsApi
* @version V1
*/
export default class TeamsApi {

    /**
    * Constructs a new TeamsApi. 
    * @alias module:api/TeamsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createUsersTeam operation.
     * @callback module:api/TeamsApi~createUsersTeamCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AshoreUsersTeam} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/WriteTeamRequest} opts.writeTeamRequest 
     * @param {module:api/TeamsApi~createUsersTeamCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AshoreUsersTeam}
     */
    createUsersTeam(opts, callback) {
      opts = opts || {};
      let postBody = opts['writeTeamRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AshoreUsersTeam;
      return this.apiClient.callApi(
        '/team', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteUsersTeam operation.
     * @callback module:api/TeamsApi~deleteUsersTeamCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} teamId 
     * @param {module:api/TeamsApi~deleteUsersTeamCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deleteUsersTeam(teamId, callback) {
      let postBody = null;
      // verify the required parameter 'teamId' is set
      if (teamId === undefined || teamId === null) {
        throw new Error("Missing the required parameter 'teamId' when calling deleteUsersTeam");
      }

      let pathParams = {
        'teamId': teamId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/team/{teamId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountTeamNames operation.
     * @callback module:api/TeamsApi~getAccountTeamNamesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<String>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TeamsApi~getAccountTeamNamesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<String>}
     */
    getAccountTeamNames(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/teams/account/names', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllTeamsForAccount operation.
     * @callback module:api/TeamsApi~getAllTeamsForAccountCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AshoreUsersTeam>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TeamsApi~getAllTeamsForAccountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AshoreUsersTeam>}
     */
    getAllTeamsForAccount(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AshoreUsersTeam];
      return this.apiClient.callApi(
        '/teams/account', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCurrentUserTeamNames operation.
     * @callback module:api/TeamsApi~getCurrentUserTeamNamesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<String>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TeamsApi~getCurrentUserTeamNamesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<String>}
     */
    getCurrentUserTeamNames(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/teams/user/names', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCurrentUserTeams operation.
     * @callback module:api/TeamsApi~getCurrentUserTeamsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AshoreUsersTeam>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/TeamsApi~getCurrentUserTeamsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AshoreUsersTeam>}
     */
    getCurrentUserTeams(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [AshoreUsersTeam];
      return this.apiClient.callApi(
        '/teams/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the reAssignUsersToAnotherTeam operation.
     * @callback module:api/TeamsApi~reAssignUsersToAnotherTeamCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ReAssignUsersTeamRequest} opts.reAssignUsersTeamRequest 
     * @param {module:api/TeamsApi~reAssignUsersToAnotherTeamCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    reAssignUsersToAnotherTeam(opts, callback) {
      opts = opts || {};
      let postBody = opts['reAssignUsersTeamRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/account/team/reassign-users', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUsersTeam operation.
     * @callback module:api/TeamsApi~updateUsersTeamCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AshoreUsersTeam} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} teamId 
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateTeamRequest} opts.updateTeamRequest 
     * @param {module:api/TeamsApi~updateUsersTeamCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AshoreUsersTeam}
     */
    updateUsersTeam(teamId, opts, callback) {
      opts = opts || {};
      let postBody = opts['updateTeamRequest'];
      // verify the required parameter 'teamId' is set
      if (teamId === undefined || teamId === null) {
        throw new Error("Missing the required parameter 'teamId' when calling updateUsersTeam");
      }

      let pathParams = {
        'teamId': teamId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AshoreUsersTeam;
      return this.apiClient.callApi(
        '/team/{teamId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
