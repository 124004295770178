/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class AppEventType.
* @enum {}
* @readonly
*/
export default class AppEventType {
    
        /**
         * value: 1
         * @const
         */
        "1" = 1;

    
        /**
         * value: 2
         * @const
         */
        "2" = 2;

    
        /**
         * value: 3
         * @const
         */
        "3" = 3;

    
        /**
         * value: 4
         * @const
         */
        "4" = 4;

    
        /**
         * value: 5
         * @const
         */
        "5" = 5;

    
        /**
         * value: 6
         * @const
         */
        "6" = 6;

    
        /**
         * value: 7
         * @const
         */
        "7" = 7;

    
        /**
         * value: 8
         * @const
         */
        "8" = 8;

    
        /**
         * value: 9
         * @const
         */
        "9" = 9;

    
        /**
         * value: 10
         * @const
         */
        "10" = 10;

    
        /**
         * value: 11
         * @const
         */
        "11" = 11;

    
        /**
         * value: 31
         * @const
         */
        "31" = 31;

    
        /**
         * value: 32
         * @const
         */
        "32" = 32;

    
        /**
         * value: 33
         * @const
         */
        "33" = 33;

    
        /**
         * value: 34
         * @const
         */
        "34" = 34;

    
        /**
         * value: 35
         * @const
         */
        "35" = 35;

    
        /**
         * value: 36
         * @const
         */
        "36" = 36;

    
        /**
         * value: 37
         * @const
         */
        "37" = 37;

    
        /**
         * value: 38
         * @const
         */
        "38" = 38;

    
        /**
         * value: 39
         * @const
         */
        "39" = 39;

    
        /**
         * value: 40
         * @const
         */
        "40" = 40;

    
        /**
         * value: 41
         * @const
         */
        "41" = 41;

    
        /**
         * value: 42
         * @const
         */
        "42" = 42;

    
        /**
         * value: 43
         * @const
         */
        "43" = 43;

    
        /**
         * value: 44
         * @const
         */
        "44" = 44;

    
        /**
         * value: 45
         * @const
         */
        "45" = 45;

    
        /**
         * value: 46
         * @const
         */
        "46" = 46;

    
        /**
         * value: 47
         * @const
         */
        "47" = 47;

    
        /**
         * value: 48
         * @const
         */
        "48" = 48;

    
        /**
         * value: 49
         * @const
         */
        "49" = 49;

    
        /**
         * value: 50
         * @const
         */
        "50" = 50;

    
        /**
         * value: 51
         * @const
         */
        "51" = 51;

    
        /**
         * value: 52
         * @const
         */
        "52" = 52;

    
        /**
         * value: 53
         * @const
         */
        "53" = 53;

    
        /**
         * value: 54
         * @const
         */
        "54" = 54;

    
        /**
         * value: 55
         * @const
         */
        "55" = 55;

    
        /**
         * value: 56
         * @const
         */
        "56" = 56;

    
        /**
         * value: 57
         * @const
         */
        "57" = 57;

    
        /**
         * value: 58
         * @const
         */
        "58" = 58;

    
        /**
         * value: 59
         * @const
         */
        "59" = 59;

    
        /**
         * value: 60
         * @const
         */
        "60" = 60;

    
        /**
         * value: 61
         * @const
         */
        "61" = 61;

    

    /**
    * Returns a <code>AppEventType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/AppEventType} The enum <code>AppEventType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

