/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Account from './Account';
import ApiToken from './ApiToken';
import AppNotification from './AppNotification';
import AshoreUsersTeam from './AshoreUsersTeam';
import EmailAuthorizationSimple from './EmailAuthorizationSimple';
import EmailSendData from './EmailSendData';
import UserPermissions from './UserPermissions';
import UserSetting from './UserSetting';

/**
 * The User model module.
 * @module model/User
 * @version V1
 */
class User {
    /**
     * Constructs a new <code>User</code>.
     * @alias module:model/User
     */
    constructor() { 
        
        User.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/User} obj Optional instance to populate.
     * @return {module:model/User} The populated <code>User</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new User();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('initials')) {
                obj['initials'] = ApiClient.convertToType(data['initials'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('lastLogin')) {
                obj['lastLogin'] = ApiClient.convertToType(data['lastLogin'], 'Date');
            }
            if (data.hasOwnProperty('profileImageUrl')) {
                obj['profileImageUrl'] = ApiClient.convertToType(data['profileImageUrl'], 'String');
            }
            if (data.hasOwnProperty('emailVerified')) {
                obj['emailVerified'] = ApiClient.convertToType(data['emailVerified'], 'Boolean');
            }
            if (data.hasOwnProperty('productTourState')) {
                obj['productTourState'] = ApiClient.convertToType(data['productTourState'], ['String']);
            }
            if (data.hasOwnProperty('permissions')) {
                obj['permissions'] = UserPermissions.constructFromObject(data['permissions']);
            }
            if (data.hasOwnProperty('apiToken')) {
                obj['apiToken'] = ApiToken.constructFromObject(data['apiToken']);
            }
            if (data.hasOwnProperty('notifications')) {
                obj['notifications'] = ApiClient.convertToType(data['notifications'], [AppNotification]);
            }
            if (data.hasOwnProperty('account')) {
                obj['account'] = Account.constructFromObject(data['account']);
            }
            if (data.hasOwnProperty('emailAuthorization')) {
                obj['emailAuthorization'] = EmailAuthorizationSimple.constructFromObject(data['emailAuthorization']);
            }
            if (data.hasOwnProperty('userSettings')) {
                obj['userSettings'] = UserSetting.constructFromObject(data['userSettings']);
            }
            if (data.hasOwnProperty('emailSettings')) {
                obj['emailSettings'] = ApiClient.convertToType(data['emailSettings'], [EmailSendData]);
            }
            if (data.hasOwnProperty('teams')) {
                obj['teams'] = ApiClient.convertToType(data['teams'], [AshoreUsersTeam]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
User.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
User.prototype['accountId'] = undefined;

/**
 * @member {String} name
 */
User.prototype['name'] = undefined;

/**
 * @member {String} initials
 */
User.prototype['initials'] = undefined;

/**
 * @member {String} email
 */
User.prototype['email'] = undefined;

/**
 * @member {String} phone
 */
User.prototype['phone'] = undefined;

/**
 * @member {String} title
 */
User.prototype['title'] = undefined;

/**
 * @member {Date} lastLogin
 */
User.prototype['lastLogin'] = undefined;

/**
 * @member {String} profileImageUrl
 */
User.prototype['profileImageUrl'] = undefined;

/**
 * @member {Boolean} emailVerified
 */
User.prototype['emailVerified'] = undefined;

/**
 * @member {Array.<String>} productTourState
 */
User.prototype['productTourState'] = undefined;

/**
 * @member {module:model/UserPermissions} permissions
 */
User.prototype['permissions'] = undefined;

/**
 * @member {module:model/ApiToken} apiToken
 */
User.prototype['apiToken'] = undefined;

/**
 * @member {Array.<module:model/AppNotification>} notifications
 */
User.prototype['notifications'] = undefined;

/**
 * @member {module:model/Account} account
 */
User.prototype['account'] = undefined;

/**
 * @member {module:model/EmailAuthorizationSimple} emailAuthorization
 */
User.prototype['emailAuthorization'] = undefined;

/**
 * @member {module:model/UserSetting} userSettings
 */
User.prototype['userSettings'] = undefined;

/**
 * @member {Array.<module:model/EmailSendData>} emailSettings
 */
User.prototype['emailSettings'] = undefined;

/**
 * @member {Array.<module:model/AshoreUsersTeam>} teams
 */
User.prototype['teams'] = undefined;






export default User;

