/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ClaimsIdentity from './ClaimsIdentity';

/**
 * The Claim model module.
 * @module model/Claim
 * @version V1
 */
class Claim {
    /**
     * Constructs a new <code>Claim</code>.
     * @alias module:model/Claim
     */
    constructor() { 
        
        Claim.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Claim</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Claim} obj Optional instance to populate.
     * @return {module:model/Claim} The populated <code>Claim</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Claim();

            if (data.hasOwnProperty('issuer')) {
                obj['issuer'] = ApiClient.convertToType(data['issuer'], 'String');
            }
            if (data.hasOwnProperty('originalIssuer')) {
                obj['originalIssuer'] = ApiClient.convertToType(data['originalIssuer'], 'String');
            }
            if (data.hasOwnProperty('properties')) {
                obj['properties'] = ApiClient.convertToType(data['properties'], {'String': 'String'});
            }
            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ClaimsIdentity.constructFromObject(data['subject']);
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'String');
            }
            if (data.hasOwnProperty('valueType')) {
                obj['valueType'] = ApiClient.convertToType(data['valueType'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} issuer
 */
Claim.prototype['issuer'] = undefined;

/**
 * @member {String} originalIssuer
 */
Claim.prototype['originalIssuer'] = undefined;

/**
 * @member {Object.<String, String>} properties
 */
Claim.prototype['properties'] = undefined;

/**
 * @member {module:model/ClaimsIdentity} subject
 */
Claim.prototype['subject'] = undefined;

/**
 * @member {String} type
 */
Claim.prototype['type'] = undefined;

/**
 * @member {String} value
 */
Claim.prototype['value'] = undefined;

/**
 * @member {String} valueType
 */
Claim.prototype['valueType'] = undefined;






export default Claim;

