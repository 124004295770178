/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Product model module.
 * @module model/Product
 * @version V1
 */
class Product {
    /**
     * Constructs a new <code>Product</code>.
     * @alias module:model/Product
     */
    constructor() { 
        
        Product.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Product</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Product} obj Optional instance to populate.
     * @return {module:model/Product} The populated <code>Product</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Product();

            if (data.hasOwnProperty('sku')) {
                obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
            }
            if (data.hasOwnProperty('stripePlanId')) {
                obj['stripePlanId'] = ApiClient.convertToType(data['stripePlanId'], 'String');
            }
            if (data.hasOwnProperty('stripePricingPlanId')) {
                obj['stripePricingPlanId'] = ApiClient.convertToType(data['stripePricingPlanId'], 'String');
            }
            if (data.hasOwnProperty('publicUrlDescription')) {
                obj['publicUrlDescription'] = ApiClient.convertToType(data['publicUrlDescription'], 'String');
            }
            if (data.hasOwnProperty('publicName')) {
                obj['publicName'] = ApiClient.convertToType(data['publicName'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('iconUri')) {
                obj['iconUri'] = ApiClient.convertToType(data['iconUri'], 'String');
            }
            if (data.hasOwnProperty('cycleMonths')) {
                obj['cycleMonths'] = ApiClient.convertToType(data['cycleMonths'], 'Number');
            }
            if (data.hasOwnProperty('userLimit')) {
                obj['userLimit'] = ApiClient.convertToType(data['userLimit'], 'Number');
            }
            if (data.hasOwnProperty('whiteLabelEnabled')) {
                obj['whiteLabelEnabled'] = ApiClient.convertToType(data['whiteLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('isSubscription')) {
                obj['isSubscription'] = ApiClient.convertToType(data['isSubscription'], 'Boolean');
            }
            if (data.hasOwnProperty('domainWhiteLabelEnabled')) {
                obj['domainWhiteLabelEnabled'] = ApiClient.convertToType(data['domainWhiteLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('priceIsPerSeat')) {
                obj['priceIsPerSeat'] = ApiClient.convertToType(data['priceIsPerSeat'], 'Boolean');
            }
            if (data.hasOwnProperty('minBillingUsers')) {
                obj['minBillingUsers'] = ApiClient.convertToType(data['minBillingUsers'], 'Number');
            }
            if (data.hasOwnProperty('unlimitedUsers')) {
                obj['unlimitedUsers'] = ApiClient.convertToType(data['unlimitedUsers'], 'Boolean');
            }
            if (data.hasOwnProperty('unlimitedFiles')) {
                obj['unlimitedFiles'] = ApiClient.convertToType(data['unlimitedFiles'], 'Boolean');
            }
            if (data.hasOwnProperty('fileSendCreditPerMonth')) {
                obj['fileSendCreditPerMonth'] = ApiClient.convertToType(data['fileSendCreditPerMonth'], 'Number');
            }
            if (data.hasOwnProperty('fileStoreMaxMonths')) {
                obj['fileStoreMaxMonths'] = ApiClient.convertToType(data['fileStoreMaxMonths'], 'Number');
            }
            if (data.hasOwnProperty('gbLimit')) {
                obj['gbLimit'] = ApiClient.convertToType(data['gbLimit'], 'Number');
            }
            if (data.hasOwnProperty('unitPrice')) {
                obj['unitPrice'] = ApiClient.convertToType(data['unitPrice'], 'Number');
            }
            if (data.hasOwnProperty('calculatedTotalPrice')) {
                obj['calculatedTotalPrice'] = ApiClient.convertToType(data['calculatedTotalPrice'], 'Number');
            }
            if (data.hasOwnProperty('priceWasCalculated')) {
                obj['priceWasCalculated'] = ApiClient.convertToType(data['priceWasCalculated'], 'Boolean');
            }
            if (data.hasOwnProperty('features')) {
                obj['features'] = ApiClient.convertToType(data['features'], ['String']);
            }
            if (data.hasOwnProperty('proofTypes')) {
                obj['proofTypes'] = ApiClient.convertToType(data['proofTypes'], ['String']);
            }
            if (data.hasOwnProperty('allowNewAccounts')) {
                obj['allowNewAccounts'] = ApiClient.convertToType(data['allowNewAccounts'], 'Boolean');
            }
            if (data.hasOwnProperty('stackable')) {
                obj['stackable'] = ApiClient.convertToType(data['stackable'], 'Boolean');
            }
            if (data.hasOwnProperty('effectiveDate')) {
                obj['effectiveDate'] = ApiClient.convertToType(data['effectiveDate'], 'Date');
            }
            if (data.hasOwnProperty('terminatedDate')) {
                obj['terminatedDate'] = ApiClient.convertToType(data['terminatedDate'], 'Date');
            }
            if (data.hasOwnProperty('grandfatheredDate')) {
                obj['grandfatheredDate'] = ApiClient.convertToType(data['grandfatheredDate'], 'Date');
            }
            if (data.hasOwnProperty('isLifeTime')) {
                obj['isLifeTime'] = ApiClient.convertToType(data['isLifeTime'], 'Boolean');
            }
            if (data.hasOwnProperty('maximumCodeRedemptions')) {
                obj['maximumCodeRedemptions'] = ApiClient.convertToType(data['maximumCodeRedemptions'], 'Number');
            }
            if (data.hasOwnProperty('proratedPriceForFirstCycle')) {
                obj['proratedPriceForFirstCycle'] = ApiClient.convertToType(data['proratedPriceForFirstCycle'], 'Number');
            }
            if (data.hasOwnProperty('publicCyclePrice')) {
                obj['publicCyclePrice'] = ApiClient.convertToType(data['publicCyclePrice'], 'String');
            }
            if (data.hasOwnProperty('annualDiscountPercent')) {
                obj['annualDiscountPercent'] = ApiClient.convertToType(data['annualDiscountPercent'], 'Number');
            }
            if (data.hasOwnProperty('monthlyCostWhenPaidAnnually')) {
                obj['monthlyCostWhenPaidAnnually'] = ApiClient.convertToType(data['monthlyCostWhenPaidAnnually'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} sku
 */
Product.prototype['sku'] = undefined;

/**
 * @member {String} stripePlanId
 */
Product.prototype['stripePlanId'] = undefined;

/**
 * @member {String} stripePricingPlanId
 */
Product.prototype['stripePricingPlanId'] = undefined;

/**
 * @member {String} publicUrlDescription
 */
Product.prototype['publicUrlDescription'] = undefined;

/**
 * @member {String} publicName
 */
Product.prototype['publicName'] = undefined;

/**
 * @member {String} description
 */
Product.prototype['description'] = undefined;

/**
 * @member {String} iconUri
 */
Product.prototype['iconUri'] = undefined;

/**
 * @member {Number} cycleMonths
 */
Product.prototype['cycleMonths'] = undefined;

/**
 * @member {Number} userLimit
 */
Product.prototype['userLimit'] = undefined;

/**
 * @member {Boolean} whiteLabelEnabled
 */
Product.prototype['whiteLabelEnabled'] = undefined;

/**
 * @member {Boolean} isSubscription
 */
Product.prototype['isSubscription'] = undefined;

/**
 * @member {Boolean} domainWhiteLabelEnabled
 */
Product.prototype['domainWhiteLabelEnabled'] = undefined;

/**
 * @member {Boolean} priceIsPerSeat
 */
Product.prototype['priceIsPerSeat'] = undefined;

/**
 * @member {Number} minBillingUsers
 */
Product.prototype['minBillingUsers'] = undefined;

/**
 * @member {Boolean} unlimitedUsers
 */
Product.prototype['unlimitedUsers'] = undefined;

/**
 * @member {Boolean} unlimitedFiles
 */
Product.prototype['unlimitedFiles'] = undefined;

/**
 * @member {Number} fileSendCreditPerMonth
 */
Product.prototype['fileSendCreditPerMonth'] = undefined;

/**
 * @member {Number} fileStoreMaxMonths
 */
Product.prototype['fileStoreMaxMonths'] = undefined;

/**
 * @member {Number} gbLimit
 */
Product.prototype['gbLimit'] = undefined;

/**
 * @member {Number} unitPrice
 */
Product.prototype['unitPrice'] = undefined;

/**
 * @member {Number} calculatedTotalPrice
 */
Product.prototype['calculatedTotalPrice'] = undefined;

/**
 * @member {Boolean} priceWasCalculated
 */
Product.prototype['priceWasCalculated'] = undefined;

/**
 * @member {Array.<String>} features
 */
Product.prototype['features'] = undefined;

/**
 * @member {Array.<String>} proofTypes
 */
Product.prototype['proofTypes'] = undefined;

/**
 * @member {Boolean} allowNewAccounts
 */
Product.prototype['allowNewAccounts'] = undefined;

/**
 * @member {Boolean} stackable
 */
Product.prototype['stackable'] = undefined;

/**
 * @member {Date} effectiveDate
 */
Product.prototype['effectiveDate'] = undefined;

/**
 * @member {Date} terminatedDate
 */
Product.prototype['terminatedDate'] = undefined;

/**
 * @member {Date} grandfatheredDate
 */
Product.prototype['grandfatheredDate'] = undefined;

/**
 * @member {Boolean} isLifeTime
 */
Product.prototype['isLifeTime'] = undefined;

/**
 * @member {Number} maximumCodeRedemptions
 */
Product.prototype['maximumCodeRedemptions'] = undefined;

/**
 * @member {Number} proratedPriceForFirstCycle
 */
Product.prototype['proratedPriceForFirstCycle'] = undefined;

/**
 * @member {String} publicCyclePrice
 */
Product.prototype['publicCyclePrice'] = undefined;

/**
 * @member {Number} annualDiscountPercent
 */
Product.prototype['annualDiscountPercent'] = undefined;

/**
 * @member {String} monthlyCostWhenPaidAnnually
 */
Product.prototype['monthlyCostWhenPaidAnnually'] = undefined;






export default Product;

